import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, radius, zIndex } from 'driverama-core/styles/variables'

export const SWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;

  @media ${media.lte('MD')} {
    width: calc(100vw - ${size(13)});
  }
`

export const SImageShowWrapper = styled.div`
  display: flex;
  margin-top: ${size(2)};
  padding: ${size(4)};
  background-color: ${color('black', 0.4)};
  overflow-x: scroll;
  z-index: 1;
  width: 100vw;
  height: ${size(48)};

  @media ${media.lte('MD')} {
    margin-top: ${size(20)};
  }

  @media ${media.lte('LG')} {
    height: ${size(42)};
    margin-bottom: ${size(25)};
  }

  @media ${media.lte('MD')} {
    height: ${size(34)};
  }

  @media ${media.lte('mobile')} {
    height: ${size(26)};
    margin-top: ${size(2)};
    margin-bottom: ${size(14)};
  }
`

export const SImageWrapper = styled.div`
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  min-width: ${size(45)};
  height: ${size(34)};
  margin-right: ${size(2)};
  position: relative;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  @media ${media.lte('LG')} {
    min-width: ${size(45)};
    height: ${size(28)};
  }

  @media ${media.lte('MD')} {
    min-width: ${size(35)};
    height: ${size(20)};
    margin-right: ${size(4)};
  }

  @media ${media.lte('mobile')} {
    min-width: ${size(18)};
    height: ${size(12)};
  }
`

export const SImageSmall = styled.img<{ active: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: ${radius('corner')};
  border: ${(props: { active: boolean }) =>
    props.active ? `2px solid ${color('night-l-200')}` : 'none'};
  object-fit: cover;
  cursor: pointer;
`

export const SActiveImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  max-width: ${size(245)};
  border-radius: ${radius('corner')};
`

export const SActiveImage = styled(motion.img)<{
  isDraggable: boolean
  showFullImg?: boolean
}>`
  min-height: ${size(50)};
  object-fit: ${({ showFullImg }) => (showFullImg ? 'contain' : 'cover')};
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: ${({ isDraggable }) => (isDraggable ? 'grab' : 'auto')};
`

export const SMagnifier = styled.div`
  position: absolute;
  pointer-events: none;
  border: 1px solid lightgray;
  background-color: white;
  background-repeat: no-repeat;
  z-index: ${zIndex('notification')};
  height: ${size(30)};
  width: ${size(30)};
  border-radius: ${radius('corner-smaller')};
`

export const SArrowButtonRight = styled.div<{
  isDisabled?: boolean
  isAbsolute?: boolean
}>`
  border: 1px solid ${color('white', 0.5)};
  border-radius: ${radius('full')};
  color: ${color('white')};
  width: ${size(10)};
  height: ${size(10)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  & svg {
    height: ${size(4)};
    width: ${size(4)};
  }
  @media ${media.lte('LG')} {
    display: none;
  }
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      border: 1px solid ${color('white', 0.2)};
      color: ${color('white', 0.3)};
    `}
  ${({ isAbsolute }) =>
    isAbsolute &&
    css`
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: ${size(-21)};
      width: ${size(16)};
      height: ${size(16)};

      & svg {
        height: ${size(6)};
        width: ${size(6)};
      }
      @media ${media.lte('LG')} {
        display: flex;
        top: calc(100% - ${size(12)});
        right: 0;
        z-index: ${zIndex('order-1')};
      }

      @media ${media.lte('mobile')} {
        top: calc(100% - ${size(4)});
      }
    `}
`

export const SArrowButtonLeft = styled(SArrowButtonRight)<{
  isDisabled?: boolean
  isAbsolute?: boolean
}>`
  transform: rotate(-180deg);
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `}
  ${({ isAbsolute }) =>
    isAbsolute &&
    css`
      left: ${size(-21)};
      transform: rotate(-180deg) translateY(50%);
      @media ${media.lte('LG')} {
        display: flex;
        top: calc(100% - ${size(12)});
        left: 0;
        z-index: ${zIndex('order-1')};
      }

      @media ${media.lte('mobile')} {
        top: calc(100% - ${size(4)});
      }
    `}
`

export const SActiveImageSmallOverlay = styled(motion.div)`
  background-color: ${color('night-l-200', 0.4)};
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: ${radius('corner')};
`

export const SPaginationWrapper = styled.div`
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  top: ${size(9)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${size(10)};
  @media ${media.lte('LG')} {
    top: ${size(12)};
  }
`
