import { StrikeTextBody } from 'components/diff/StrikeTextBody'
import { TextBody } from 'driverama-core/components/text/Text'
import { color } from 'driverama-core/styles/variables'
import IconCheck from 'images/icons/IconCheck.svg'
import IconCircleFilled from 'images/icons/IconCheckCircleFilled.svg'
import { LovFeatureWithDiff } from './Features.utils'

export function FeatureItem(props: LovFeatureWithDiff) {
  if (props.changeType === 'ARRAY_ELEMENT_ADDED') {
    return (
      <>
        <IconCircleFilled color={color('black')} />
        <TextBody color="black">{props.name}</TextBody>
      </>
    )
  }

  if (props.changeType === 'ARRAY_ELEMENT_REMOVED') {
    return (
      <>
        <IconCheck color={color('night-text-light')} />
        <StrikeTextBody>{props.name}</StrikeTextBody>
      </>
    )
  }

  return (
    <>
      <IconCheck />
      <TextBody>{props.name}</TextBody>
    </>
  )
}
