import { AuctionState } from 'sections/auctions/detail/AuctionsDetail.utils'
import { useAuctionState } from './AuctionState.utils'
import { Tag } from 'driverama-core/components/tag/Tag'

interface Props {
  state: AuctionState
}

export function AuctionStateTag({ state }: Props) {
  const auctionState = useAuctionState(state)

  return auctionState ? (
    <Tag variant={auctionState.variant}>{auctionState.label}</Tag>
  ) : null
}
