import { css } from '@emotion/react'
import { Flex } from 'driverama-core/components/Flex'
import { InputNumberNullableControlled } from 'driverama-core/components/inputNumber/InputNumberNullableControlled'
import { SelectUncontrolled } from 'driverama-core/components/select/SelectUncontrolled'
import { isString, nameOf } from 'driverama-core/utils/types'
import { Controller, get, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getCurrencyOptions } from 'sections/auctions/modal/AuctionsModal.utils'
import { FormValues } from './AuctionDetailOtherForm.utils'

type Props = {
  disabledEdit: boolean
}

export function AuctionDetailOtherSellingPrice({ disabledEdit }: Props) {
  const { t } = useTranslation(['auction'])
  const form = useFormContext<FormValues>()
  const sellingPriceCurrencyCode = useWatch<FormValues>({
    name: 'sellingPriceCurrencyCode',
    control: form.control
  })

  const currency = isString(sellingPriceCurrencyCode)
    ? sellingPriceCurrencyCode === 'EUR'
      ? '€'
      : sellingPriceCurrencyCode
    : undefined

  return (
    <Flex variant="row" gap={2}>
      <InputNumberNullableControlled
        css={css`
          text-align: right;
        `}
        name={nameOf<FormValues>('sellingPrice')}
        isDisabled={disabledEdit}
        currency={currency}
      />

      <Controller
        name="sellingPriceCurrencyCode"
        control={form.control}
        render={({ field, formState }) => (
          <SelectUncontrolled
            {...field}
            value={field.value ?? undefined}
            label={t('auction:modal_seller_currency')}
            emptyLabel={t('auction:modal_seller_currency')}
            options={getCurrencyOptions(null)}
            error={get(formState.errors, 'sellingPriceCurrencyCode.message')}
          />
        )}
      />
    </Flex>
  )
}
