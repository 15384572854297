import {
  TextBody,
  TextBodyBold,
  TextSubhead
} from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import { SGrid, SItem } from './AuctionDetailLocation.styled'
import { components } from 'driverama-core/api/driverama/generated/auctions'
import { LocationBranch } from './locationBranch/LocationBranch'

interface Props {
  appointment?: components['schemas']['AppointmentResponse']
}

export function AuctionsDetailLocation(props: Props) {
  const { appointment } = props
  const { t } = useTranslation(['auction'])

  return (
    <section>
      <TextSubhead>{t('auction:detail_location')}</TextSubhead>

      <SGrid>
        {appointment && (
          <SItem>
            {appointment.location === 'BRANCH' && (
              <>
                <TextBody>
                  {t('auction:detail_parameters_location_branch')}
                </TextBody>

                <LocationBranch branchId={appointment.branchId} />
              </>
            )}

            {appointment.location === 'MOBILE' && (
              <>
                <TextBody>
                  {t('auction:detail_parameters_location_mobile')}
                </TextBody>
                <TextBodyBold>
                  {[
                    appointment.address?.address,
                    appointment.address?.postCode,
                    appointment.address?.town
                  ].join(', ')}
                </TextBodyBold>
              </>
            )}
          </SItem>
        )}
      </SGrid>
    </section>
  )
}
