import { URLS } from 'constants/api'
import { paths } from 'driverama-core/api/driverama/generated/auctions'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'

type ChangeWinnerResponse =
  | paths['/admin/auctions/{auctionId}/change-winner']['post']['responses']['204']
  | undefined
export type ChangeWinnerBody = paths['/admin/auctions/{auctionId}/change-winner']['post']['requestBody']['content']['application/json']

type MutationOptions = UseMutationOptions<
  ChangeWinnerResponse,
  unknown,
  ChangeWinnerBody
>

async function changeAuctionWinner(
  auctionId?: string,
  body?: ChangeWinnerBody
) {
  const res = await apiAuthFetcher<ChangeWinnerResponse>(
    URLS.changeAuctionWinner(auctionId),
    {
      method: HTTPMethod.POST,
      body
    }
  )

  return res.json
}

export function useAuctionWinnerMutation(
  auctionId?: string,
  options?: MutationOptions
) {
  return useMutation<ChangeWinnerResponse, unknown, ChangeWinnerBody>(
    body => changeAuctionWinner(auctionId, body),
    options
  )
}
