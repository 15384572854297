import { StrikeTextBody } from 'components/diff/StrikeTextBody'
import { StrikeTextBodyBold } from 'components/diff/StrikeTextBodyBold'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { Tag } from 'driverama-core/components/tag/Tag'
import { TextBody, TextBodyBold } from 'driverama-core/components/text/Text'
import IconWarning from 'driverama-core/images/icons/IconWarningFull.svg'
import { useTranslation } from 'react-i18next'
import { AccidentItemWithDiff } from './Accidents.utils'

export function AccidentItem({
  label,
  value,
  diff,
  state
}: AccidentItemWithDiff) {
  const { t } = useTranslation(['auction'])

  if (diff?.changeType === 'VALUE_ADDED') {
    return (
      <>
        <Flex variant="row" align="center">
          <TextBody>{label}</TextBody>
          <Spacer size={2} axis="horizontal" />
          <Tag variant="dark">{t('auction:diff_value_added_label')}</Tag>
        </Flex>

        <Flex variant="row" align="center" gap={1}>
          <TextBodyBold>{value}</TextBodyBold>

          {(state === 'MEDIUM' || state === 'BAD') && (
            <IconWarning width={18} />
          )}
        </Flex>
      </>
    )
  }

  if (diff?.changeType === 'VALUE_CHANGED') {
    return (
      <>
        <TextBody>{label}</TextBody>

        <Flex variant="row" align="center">
          <StrikeTextBodyBold>{diff?.oldValue}</StrikeTextBodyBold>
          <Spacer size={2} axis="horizontal" />
          <TextBodyBold>{value}</TextBodyBold>
        </Flex>
      </>
    )
  }

  if (diff?.changeType === 'VALUE_REMOVED') {
    return (
      <>
        <StrikeTextBody>{label}</StrikeTextBody>
        <StrikeTextBodyBold>{diff?.oldValue}</StrikeTextBodyBold>
      </>
    )
  }

  return (
    <>
      <TextBody>{label}</TextBody>
      <TextBodyBold>
        <Flex variant="row" gap={2} align="center">
          <span>{value || '-'}</span>
          {(state === 'MEDIUM' || state === 'BAD') && (
            <IconWarning width={18} />
          )}
        </Flex>
      </TextBodyBold>
    </>
  )
}
