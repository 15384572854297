import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextSubhead } from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import { CarDetailMapped } from '../AuctionsDetail.utils'
import { AccidentItem } from '../diff/accidents/AccidentItem'
import { SGrid, SItem } from './AuctionsDetailAccidents.styled'
import {
  AccidentsDiffTypes,
  parameterColumns,
  useAccidentDiffs
} from './AuctionsDetailAccidents.utils'

type Props = {
  mappedData: CarDetailMapped
  diffs: AccidentsDiffTypes
}

export function AuctionsDetailAccidents({ mappedData, diffs }: Props) {
  const { t } = useTranslation(['core', 'auction'])

  const processedDiffs = useAccidentDiffs(diffs)

  return (
    <section>
      <TextSubhead>{t('auction:detail_accidents')}</TextSubhead>
      <Spacer axis="vertical" size={4} />
      <SGrid>
        {parameterColumns(t, mappedData, processedDiffs).map((col, i) => (
          <SItem key={i}>
            <AccidentItem {...col} />
          </SItem>
        ))}
      </SGrid>
    </section>
  )
}
