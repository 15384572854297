import { ErrorApi } from 'driverama-core/components/errorApi/ErrorApi'
import { useTranslation } from 'react-i18next'
import { SContainer } from './AuctionsDetailError.styled'

export function AuctionsDetailError() {
  const { t } = useTranslation(['core'])

  return (
    <SContainer>
      <ErrorApi
        title={t('core:error_api_title')}
        message={t('core:error_api_message')}
      />
    </SContainer>
  )
}
