import { AuctionDiff } from 'api/driverama/auctions/diff'
import { CarTechnicalCategory } from 'api/driverama/cars/carTechnicalCategories'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextHeader } from 'driverama-core/components/text/Text'
import { AnimatePresence } from 'framer-motion'
import IconChevronDown from 'images/icons/IconChevronDown.svg'
import { useEffect, useState } from 'react'
import { AuctionsDetailTechnicalItemContent } from 'sections/auctions/detail/technical/item/content/AuctionsDetailTechnicalItemContent'
import {
  SArrow,
  SCheck,
  SContainer,
  SHeader,
  SHeaderIcons,
  SIconImage
} from './AuctionsDetailTechnicalItem.styled'
import { ProblematicItemsCount } from './ProblematicItemsCount'
interface Props extends CarTechnicalCategory {
  carId: string
  auctionId: string | undefined
  title: string
  onDetailExpand: (name: string) => void
  problematicItemsCountDiff?: AuctionDiff
}

export function AuctionsDetailTechnicalItem({
  carId,
  auctionId,
  title,
  problematicItemsCount,
  hasItemPhotos,
  categoryId,
  onDetailExpand,
  problematicItemsCountDiff
}: Props) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) onDetailExpand(title)
  }, [onDetailExpand, open, title])

  useEffect(() => {
    setOpen(problematicItemsCount > 0 || !!problematicItemsCountDiff)
  }, [problematicItemsCount, problematicItemsCountDiff])

  const hasProblematicItemsToDisplay =
    problematicItemsCount > 0 || !!problematicItemsCountDiff

  return (
    <SContainer isOpen={open}>
      <SHeader
        onClick={() => setOpen(prev => !prev)}
        disabled={!hasProblematicItemsToDisplay}
      >
        <Flex variant="row" align="center">
          {hasProblematicItemsToDisplay && (
            <>
              <SArrow isOpen={open}>
                <IconChevronDown />
              </SArrow>
              <Spacer axis="horizontal" size={5} />
            </>
          )}
          <TextHeader variant="h6" as="h6">
            {title}
          </TextHeader>
        </Flex>
        <SHeaderIcons>
          {hasProblematicItemsToDisplay ? (
            <ProblematicItemsCount
              problematicCount={problematicItemsCount}
              problematicCountDiff={problematicItemsCountDiff}
            />
          ) : (
            <SCheck />
          )}
          {hasItemPhotos && (
            <SIconImage
              hasAdded={
                !!problematicItemsCountDiff?.oldValue
                  ? parseFloat(problematicItemsCountDiff.oldValue) <
                    problematicItemsCount
                  : false
              }
            />
          )}
        </SHeaderIcons>
      </SHeader>
      <AnimatePresence>
        {open && (
          <AuctionsDetailTechnicalItemContent
            carId={carId}
            categoryId={categoryId}
            auctionId={auctionId}
          />
        )}
      </AnimatePresence>
    </SContainer>
  )
}
