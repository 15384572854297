import { useCarTechnicalItems } from 'api/driverama/cars/carTechnicalItems'
import { useLovTechnicalItemsQuery } from 'api/driverama/lov/lovTechnicalItems'
import { LovTechnicalSubCategoriesResponse } from 'api/driverama/lov/lovTechnicalSubCategories'
import { isNotNil } from 'driverama-core/utils/types'
import { useContext, useMemo } from 'react'
import { AuctionDiffContext } from 'sections/auctions/detail/diff/Diff.utils'
import { processTechnicalDetailsDiffs } from 'sections/auctions/detail/diff/technicalDetails/TechnicalDetails.utils'
import { useFilesSearch } from '../../../../../../api/driverama/files'

export function useTechnicalItems(
  carId: string,
  lovSubCategories: LovTechnicalSubCategoriesResponse
) {
  const { diffs } = useContext(AuctionDiffContext)

  const lovSubCategoryIds = lovSubCategories?.content.map(sc => sc.id)

  const relevantDiffs =
    diffs?.filter(diff => diff.propertyName.match(/technicalConditionItems/)) ??
    []
  const technicalSubcategoryDiffs = processTechnicalDetailsDiffs(relevantDiffs)

  const removedDiffItems = technicalSubcategoryDiffs.filter(
    item => item.changeType === 'VALUE_REMOVED'
  )

  const items = useCarTechnicalItems(
    {
      ids: [],
      carIds: [carId],
      subCategoryIds: lovSubCategoryIds || []
    },
    { enabled: !!lovSubCategoryIds, refetchOnWindowFocus: false }
  )

  const lovItems = useLovTechnicalItemsQuery(
    {
      filter: {
        ids: [],
        subcategoryIds: lovSubCategoryIds || []
      }
    },
    undefined,
    { enabled: !!lovSubCategoryIds, refetchOnWindowFocus: false }
  )

  const photoFilesIds =
    items.data?.content.reduce((acc: string[], cur) => {
      return [
        ...acc,
        ...(cur.photos.map(photo => photo.fileId).filter(isNotNil) ?? [])
      ]
    }, []) ?? []

  const photos = useFilesSearch(
    { ids: photoFilesIds ?? [] },
    {
      enabled: !!photoFilesIds.length,
      refetchOnWindowFocus: false
    }
  )

  const data = useMemo(() => {
    if (items.data && lovItems.data) {
      const originalItems = items.data.content.map(item => ({
        name: item.itemId
          ? lovItems.data.content.find(lovItem => lovItem.id === item.itemId)
              ?.name
          : item.customItemDescription,
        ...item,
        photos:
          item.photos
            .map(photo => {
              return photos.data?.content.find(
                photos => photos.id === photo.fileId
              )
            })
            .filter(isNotNil) ?? [],
        diff: technicalSubcategoryDiffs.find(diff => diff.id === item.id)
      }))

      const removedItems = removedDiffItems.map(item => ({
        name: lovItems.data.content.find(lovItem => lovItem.id === item.itemId)
          ?.name,
        note: item.note,
        subCategoryId: item.subCategoryId,
        diff: item,
        photos: []
      }))

      return [...originalItems, ...removedItems]
    }

    return undefined
  }, [
    photos.data,
    items.data,
    lovItems.data,
    removedDiffItems,
    technicalSubcategoryDiffs
  ])

  return {
    data,
    isLoading: items.isLoading || lovItems.isLoading,
    isError: items.isError || lovItems.isError
  }
}
