import { TypedAuctionDiff } from 'api/driverama/auctions/diff'
import { LovFeature } from 'api/driverama/lov/lovFeatures'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody, TextSubhead } from 'driverama-core/components/text/Text'
import { useDetectPrint } from 'driverama-core/utils/print'
import IconCheck from 'images/icons/IconCheck.svg'
import IconPlus from 'images/icons/IconPlus.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FeatureItem } from '../diff/features/FeatureItem'
import { useFeatureDiffs } from '../diff/features/Features.utils'
import { SGrid, SItem, SMore } from './AuctionsDetailFeatures.styled'

interface Props {
  features: LovFeature[]
  diffs?: TypedAuctionDiff<'$.car.features'>[]
  carId?: string
  auctionId?: string
}

export function AuctionsDetailFeatures({
  features,
  diffs,
  carId,
  auctionId
}: Props) {
  const { t } = useTranslation(['auction'])
  const [showAll, setShowAll] = useState(false)

  const isPrinting = useDetectPrint()

  const featuresWithDiffs = useFeatureDiffs(
    features,
    diffs ?? [],
    carId,
    auctionId
  )

  const list =
    featuresWithDiffs.length > 16
      ? featuresWithDiffs.slice(0, 15)
      : featuresWithDiffs

  return (
    <section>
      <TextSubhead>{t('auction:detail_features')}</TextSubhead>
      <Spacer axis="vertical" size={6} />
      {!featuresWithDiffs.length ? (
        <TextBody>{t('auction:detail_features_empty')}</TextBody>
      ) : (
        <SGrid>
          {list.map((feature, i) => (
            <SItem key={i}>
              <FeatureItem {...feature} />
            </SItem>
          ))}
          {featuresWithDiffs.length > 16 && !showAll && !isPrinting && (
            <SMore key="more" as="button" onClick={() => setShowAll(true)}>
              <IconPlus />
              <TextBody>
                {t('auction:detail_features_more', {
                  count: features.length - 15
                })}
              </TextBody>
            </SMore>
          )}
          {(showAll || isPrinting) &&
            featuresWithDiffs.slice(15).map((feature, i) => (
              <SItem key={i}>
                <IconCheck />
                <TextBody>{feature.name}</TextBody>
              </SItem>
            ))}
        </SGrid>
      )}
    </section>
  )
}
