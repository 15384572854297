import { operations } from 'api/driverama/generated/lov'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { useQuery, UseQueryOptions } from 'react-query'

export type LovTechnicalSubCategoriesResponse =
  | operations['getSubcategory']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined
type LovTechnicalSubCategoriesParams = operations['getSubcategory']['parameters']['query']
type LovTechnicalSubCategoriesBody = operations['getSubcategory']['requestBody']['content']['application/json']
type LovTechnicalSubCategoriesOpts = UseQueryOptions<
  unknown,
  unknown,
  LovTechnicalSubCategoriesResponse
>

export function useLovTechnicalSubCategoriesQuery(
  body?: LovTechnicalSubCategoriesBody,
  searchParams?: LovTechnicalSubCategoriesParams,
  opts?: LovTechnicalSubCategoriesOpts
) {
  return useQuery(
    QUERY_KEYS.lovTechnicalSubCategories(searchParams, body),
    async () => {
      const res = await apiFetcher<LovTechnicalSubCategoriesResponse>(
        URLS.lovTechnicalSubCategories,
        { method: HTTPMethod.POST, body, searchParams }
      )
      return res.json
    },
    opts
  )
}
