import { SearchAuctionBidResponse } from 'api/driverama/auctions/auctionsBids'
import { ChangeWinnerBody } from 'api/driverama/auctions/changeAuctionWinner'
import { AuctionSearchResponseAggItem } from 'api/driverama/auctions/searchAuctionsAgg'
import { useTranslation } from 'react-i18next'
import { BID_MAX, BID_MIN } from 'sections/auctions/modal/AuctionsModal.utils'
import { useDefaultZodErrorMap } from 'utils/validations'
import { number, object, string } from 'zod'

export type FormValues = ChangeWinnerBody

export function useWinnerChangeValidationSchema() {
  const { t } = useTranslation(['auction'])

  const schema = object({
    partnerId: string(),
    winningBidPrice: number()
      .min(BID_MIN, t('auction:modal_input_min', { min: BID_MIN }))
      .max(BID_MAX, t('auction:modal_input_max', { max: BID_MAX })),
    sellingPrice: number().positive(),
    sellingPriceCurrencyCode: string().nonempty(
      t('auction:detail_other_selling_price_currency_error')
    )
  })

  const defaultErrorMap = useDefaultZodErrorMap()

  return {
    schema,
    errorMap: defaultErrorMap
  }
}

export function getDefaultFormValues(
  auction?: AuctionSearchResponseAggItem,
  mostRecentBid?: SearchAuctionBidResponse['content'][number]
) {
  return {
    winningBidPrice: auction?.winningBid?.price,
    partnerId: auction?.winningBid?.partnerErpId,
    sellingPrice: mostRecentBid?.sellingPrice,
    sellingPriceCurrencyCode: mostRecentBid?.sellingPriceCurrencyCode
  }
}
