import { AuctionDiff } from 'api/driverama/auctions/diff'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { TextBody, TextSubhead } from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import { useConditionSections } from 'sections/auctions/detail/condition/AuctionsDetailCondition.utils'
import { AuctionsDetailConditionRating } from 'sections/auctions/detail/condition/rating/AuctionsDetailConditionRating'
import { SCard, SCards, SLabel } from './AuctionsDetailCondition.styled'

interface Props {
  carId: string
  diffs?: AuctionDiff[]
}

export function AuctionsDetailCondition({ carId, diffs }: Props) {
  const { t } = useTranslation(['auction', 'core'])
  const { data, isLoading, isError } = useConditionSections(carId, diffs ?? [])

  return isLoading ? (
    <SpinnerCentered />
  ) : isError || !data ? (
    <TextBody>{t('core:error_api')}</TextBody>
  ) : (
    <section>
      <TextSubhead>{t('auction:detail_condition')}</TextSubhead>
      <Spacer axis="vertical" size={6} />
      <SCards>
        {data.map(section => (
          <SCard key={section.sectionId}>
            <AuctionsDetailConditionRating rating={section.rating} />
            <AuctionsDetailConditionRating
              isDiff
              rating={
                section.diff?.oldValue
                  ? Number(section.diff.oldValue)
                  : undefined
              }
            />
            <SLabel>{section.name}</SLabel>
          </SCard>
        ))}
      </SCards>
    </section>
  )
}
