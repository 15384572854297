import { components, operations } from 'api/driverama/generated/cars'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { UseQueryOptions, useQuery } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'

type CarFilesResponse =
  | operations['search']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined

type CarFilesBody = operations['search']['requestBody']['content']['application/json']

type FilesSearchResponse =
  | operations['searchFiles']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined

export type QueryResult = {
  url: string
  id: string
  type: components['schemas']['CarMediaTypeRequest']['type'] | null
}[]

type QueryOpts = UseQueryOptions<unknown, unknown, QueryResult>

const mediaKeysOrderList: components['schemas']['CarMediaTypeRequest']['type'][] = [
  'PHOTO_EXT_FRONT_LEFT',
  'PHOTO_EXT_FRONT',
  'PHOTO_EXT_FRONT_RIGHT',
  'PHOTO_INT_SEATS_DRIVER',
  'PHOTO_INT_SPEEDOMETER',
  'PHOTO_INT_SEATS_REAR',
  'PHOTO_INT_CENTRAL_PANEL',
  'PHOTO_EXT_REAR_LEFT',
  'PHOTO_EXT_REAR',
  'PHOTO_TRUNK',
  'PHOTO_SPARE_WHEEL',
  'PHOTO_EXT_REAR_RIGHT',
  'PHOTO_INT_SEATS_CO_DRIVER',
  'PHOTO_ENGINE_BAY',
  'PHOTO_VIN',
  'PHOTO_VIN_LABEL',
  'PHOTO_TIRES',
  'PHOTO_OTHER'
]

export function useCarFilesQuery(
  body: CarFilesBody,
  firstOnly?: boolean,
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.carFiles({ ...body, firstOnly, ...opts }),
    async () => {
      const res = await apiAuthFetcher<CarFilesResponse>(URLS.carMedia, {
        method: HTTPMethod.POST,
        body: body
      })

      const mediaList = res.json?.content

      const filesIdsSort = mediaList
        ? mediaList
            .sort(
              (a, b) =>
                mediaKeysOrderList.indexOf(a.type) -
                mediaKeysOrderList.indexOf(b.type)
            )
            .map(media => media.fileId || '') || []
        : []

      const fileIds =
        firstOnly && filesIdsSort.length ? [filesIdsSort[0]] : filesIdsSort

      if (fileIds.length !== 0) {
        const resFiles = await apiAuthFetcher<FilesSearchResponse>(
          URLS.filesSearch,
          {
            method: HTTPMethod.POST,
            body: { ids: fileIds }
          }
        )

        const files = resFiles.json

        const data =
          files?.content
            .sort((a, b) => fileIds.indexOf(a.id) - fileIds.indexOf(b.id))
            .map(files => ({
              url: files.url,
              id: files.id,
              type:
                mediaList?.find(media => media.fileId === files.id)?.type ||
                null
            })) || []

        return data
      }

      return []
    },
    opts
  )
}
