import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button } from 'driverama-core/components/button/Button'
import { Flex } from 'driverama-core/components/Flex'
import { TextBody, TextBodyBold } from 'driverama-core/components/text/Text'
import { fadeIn } from 'driverama-core/styles/animations'
import { inset } from 'driverama-core/styles/inset'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, radius, time, zIndex } from 'driverama-core/styles/variables'
import { motion } from 'framer-motion'

export const SLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: ${size(60)};
`

export const SImageContent = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: ${zIndex('order-1')};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: ${size(3)};

  width: 100%;

  padding: ${size(5)};

  color: ${color('white')};
  background-color: ${color('alert', 0.6)};

  ${TextBody}, ${TextBodyBold} {
    color: inherit;
  }
`

export const SImageContentTag = styled(TextBodyBold)`
  display: inline-block;

  padding: ${size(1)} ${size(2)};

  text-transform: uppercase;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 1px;

  color: ${color('alert')};
  background-color: ${color('white')};
`

export const SContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${size(80)}, 1fr));
  grid-gap: ${size(4)};

  animation: ${fadeIn} ${time('control')} ease-in-out;

  @media ${media.lte('laptop')} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${media.lte('MD')} {
    grid-template-columns: 1fr;
  }

  @media print {
    display: block;
    order: 999;

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
`

export const SImageContainer = styled.div<{ isClickable: boolean }>`
  position: relative;
  overflow: hidden;

  border-radius: ${radius('corner')};

  @media ${media.lte('laptop')} {
    height: ${size(50)};

    &:last-of-type {
      grid-column: 1/-1;
    }
  }

  @media print {
    display: block;
    float: left;
    width: 50%;
    page-break-inside: avoid;
    border-radius: 0;
    padding: ${size(4)};
    box-sizing: border-box;
  }

  ${({ isClickable }) =>
    isClickable &&
    css`
      @media (hover: hover) {
        &:hover {
          cursor: pointer;
        }
      }
    `}
`

export const SImageContainerOverlay = styled(Flex)`
  position: absolute;
  z-index: ${zIndex('order-1')};
  width: 100%;
  height: 100%;

  @media print {
    display: none;
  }
`

export const SPrintImage = styled.img`
  display: none;

  @media print {
    display: block;
    width: 100%;
    height: auto;
    max-width: 500px;
  }
`

export const SImage = styled.img`
  display: block;

  width: ${size(80)};
  height: ${size(60)};

  object-fit: cover;

  @media ${media.lte('laptop')} {
    width: 100%;
    height: 100%;
    position: absolute;
    ${inset(0)};
  }

  @media print {
    display: none;
  }
`

export const SImageOverlay = styled.div`
  position: absolute;
  z-index: ${zIndex('order-1')};
  width: 100%;
  height: 100%;
  background-color: ${color('night-l-100')};
  opacity: 0.4;
`

export const SImageButton = styled(Button)`
  z-index: ${zIndex('order-1')};
  max-width: ${size(45)};

  color: ${color('white')};
  border-color: ${color('white')};
  backdrop-filter: blur(4px);
  background-color: ${color('white', 0.1)};
  white-space: nowrap;

  @media ${media.lte('tablet')} {
    font-size: ${size(4)};
    padding: ${size(3)} ${size(4)};
    width: ${size(40)};
  }

  &[disabled] {
    opacity: 1;
    color: ${color('white')};
    border-color: ${color('white', 0.6)};
  }

  &:hover,
  &:focus {
    background-color: ${color('white', 0.7)};
    color: ${color('black')};
    border-color: ${color('white', 0.6)};
  }
`
