import { css } from '@emotion/react'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import IconChevronRight from 'driverama-core/images/icons/IconChevronRight.svg'
import { media } from 'driverama-core/styles/media'
import { Maybe } from 'driverama-core/utils/types'
import type { Point2D } from 'framer-motion'
import { AnimatePresence, motion } from 'framer-motion'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useMedia } from 'react-use'
import { useKeyDown } from '../../utils/hooks'
import {
  SActiveImage,
  SActiveImageSmallOverlay,
  SActiveImageWrapper,
  SArrowButtonLeft,
  SArrowButtonRight,
  SImageShowWrapper,
  SImageSmall,
  SImageWrapper,
  SMagnifier,
  SWrapper
} from './ImagesGallery.styled'
import {
  getImageIndex,
  getObjectFitSize,
  prefetchImages,
  removePixelsFromString,
  resolveImageUrl
} from './ImagesGallery.utils'

const magnifierHeight = 120
const magnifieWidth = 120
const zoomLevel = 2

interface Props {
  images: { url: Maybe<string> | undefined; imageContent?: ReactNode }[]
  showFullImg?: boolean
  logImageView?: () => void
  isImageListHidden?: boolean
  isControlsHidden?: boolean
  isMagnifierDisabled?: boolean
  openIndex?: number
  actionButton?: ReactNode
}

export function ImagesGallery({
  images,
  showFullImg,
  logImageView,
  isImageListHidden,
  isControlsHidden,
  isMagnifierDisabled,
  openIndex,
  actionButton
}: Props) {
  const [direction, setDirection] = useState('right')
  const viewedRef = useRef<string[]>([])

  const [[x, y], setXY] = useState([0, 0])
  const [[xOffset, yOffset], setXYOffset] = useState([0, 0])
  const [[imgWidth, imgHeight], setSize] = useState([0, 0])
  const [showMagnifier, setShowMagnifier] = useState(false)
  const imageListRef = useRef<HTMLDivElement>(null)
  const isSmall = useMedia(media.lte('tablet'), false)

  const [isImageLoading, setIsImageLoading] = useState(true)

  const onImageViewed = (image: Maybe<string>) => {
    if (image && viewedRef.current.includes(image)) {
      viewedRef.current.push(image)
    }
  }

  const [currentState, setCurrentState] = useState(0)

  const variants = {
    enter: (directions: string) => {
      return {
        x: directions === 'right' ? 1000 : -1000,
        opacity: 0
      }
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (directions: string) => {
      return {
        zIndex: 0,
        x: directions === 'right' ? -1000 : 1000,
        opacity: 0
      }
    }
  }

  const handleImageChange = useCallback(
    (index: number) => {
      setCurrentState(index)
      setIsImageLoading(true)
      setShowMagnifier(false)

      if (imageListRef.current) {
        const { width, marginRight } = getComputedStyle(
          imageListRef.current.childNodes[0] as HTMLDivElement
        )

        const itemWidth =
          removePixelsFromString(width) + removePixelsFromString(marginRight)

        // Timeout fixing moving with arrows - native behavior don't move scroll bar as we want
        setTimeout(() => {
          if (imageListRef.current) {
            imageListRef.current.scrollTo({
              left:
                index * itemWidth - (window.screen.width / 2 - itemWidth * 1.5),
              behavior: 'smooth'
            })
          }
        }, 1)
      }

      prefetchImages(images, index)
    },
    [images]
  )

  useEffect(() => {
    if (openIndex !== undefined) {
      handleImageChange(openIndex)
    }
  }, [handleImageChange, openIndex])

  function onDragEnd(offset: number, velocity: number) {
    const swipeConfidenceThreshold = 10000
    const swipe = Math.abs(offset) * velocity
    logImageView?.()

    if (swipe < -swipeConfidenceThreshold) {
      scroll('right')
    } else if (swipe > swipeConfidenceThreshold) {
      scroll('left')
    }
  }

  function scroll(direction: 'left' | 'right') {
    if (direction === 'left') {
      setDirection('left')
      if (currentState === 0) {
        handleImageChange(images.length - 1)
      } else {
        handleImageChange(currentState - 1)
      }
    } else {
      setDirection('right')
      if (currentState === images.length - 1) {
        handleImageChange(0)
      } else {
        handleImageChange(currentState + 1)
      }
    }
  }

  function onLeftPress() {
    scroll('left')
    logImageView?.()
    setShowMagnifier(false)
  }

  function onRightPress() {
    scroll('right')
    logImageView?.()
    setShowMagnifier(false)
  }

  useKeyDown('ArrowLeft', onLeftPress)
  useKeyDown('ArrowRight', onRightPress)

  function onMagnifierMove(elem: EventTarget & HTMLImageElement) {
    const { width, height, x, y } = getObjectFitSize(
      elem.width,
      elem.height,
      elem.naturalWidth,
      elem.naturalHeight
    )

    setXYOffset([x, y])
    setSize([width, height])

    if (!isMagnifierDisabled) {
      setShowMagnifier(true)
    }
  }

  function onDrag(point: Point2D) {
    // anchor point is settled to bottom left for comfortable right hand dragging
    setXY([point.x - magnifieWidth / 2, point.y - magnifierHeight / 2])

    if (!isMagnifierDisabled) {
      setShowMagnifier(true)
    }
  }

  const mobileDraggingEnabled = !isMagnifierDisabled && isSmall

  return (
    <SWrapper>
      {!isControlsHidden && (
        <>
          <SArrowButtonLeft
            isAbsolute={true}
            onClick={onLeftPress}
            isDisabled={images.length === 1}
          >
            <IconChevronRight />
          </SArrowButtonLeft>
          <SArrowButtonRight
            isAbsolute={true}
            onClick={onRightPress}
            isDisabled={images.length === 1}
          >
            <IconChevronRight />
          </SArrowButtonRight>
        </>
      )}
      <SActiveImageWrapper>
        <AnimatePresence>
          <motion.div
            key={`${currentState}-wrapper`}
            drag={mobileDraggingEnabled ? 'x' : false}
            onDrag={(_, { point }) => onDrag(point)}
          >
            <SActiveImage
              key={currentState}
              src={resolveImageUrl(images[currentState]?.url)}
              variants={variants}
              custom={direction}
              initial="enter"
              animate="center"
              exit="exit"
              drag={!mobileDraggingEnabled && images.length > 1 ? 'x' : false}
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              transition={{
                x: { type: 'spring', stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 }
              }}
              onDragEnd={(_, { offset, velocity }) =>
                onDragEnd(offset.x, velocity.x)
              }
              isDraggable={images.length > 1}
              showFullImg={showFullImg}
              onError={() => setIsImageLoading(false)}
              onLoad={() => {
                setIsImageLoading(false)
                onImageViewed(images[currentState]?.url)
              }}
              onTouchMove={e => onMagnifierMove(e.currentTarget)}
              onMouseEnter={e => onMagnifierMove(e.currentTarget)}
              onMouseMove={e => {
                const elem = e.currentTarget
                const { top, left } = elem.getBoundingClientRect()

                const x = e.pageX - left
                const y = e.pageY - top

                const { height, y: offY } = getObjectFitSize(
                  elem.width,
                  elem.height,
                  elem.naturalWidth,
                  elem.naturalHeight
                )

                setXY([x, y])

                if (y > height + offY || y < offY) {
                  setShowMagnifier(false)
                } else if (!isMagnifierDisabled) {
                  setShowMagnifier(true)
                }
              }}
              onMouseLeave={() => {
                if (!isMagnifierDisabled) {
                  setShowMagnifier(false)
                }
              }}
            />
          </motion.div>

          <SMagnifier
            style={{
              display: showMagnifier ? 'block' : 'none',
              top: `${y - magnifierHeight / 2}px`,
              left: `${x - magnifieWidth / 2}px`,
              backgroundImage: `url('${images[currentState]?.url ?? ''}')`,
              backgroundSize: `${imgWidth * zoomLevel}px ${
                imgHeight * zoomLevel
              }px`,

              backgroundPositionX: `${
                -x * zoomLevel + magnifieWidth / 2 + xOffset * 2
              }px`,
              backgroundPositionY: `${
                -y * zoomLevel + magnifierHeight / 2 + yOffset * 2
              }px`
            }}
          />

          {images[currentState]?.imageContent}
        </AnimatePresence>

        <AnimatePresence>
          {isImageLoading && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { delay: 0.3 }
              }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
            >
              <SpinnerCentered
                color="white"
                css={css`
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                `}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </SActiveImageWrapper>
      {!isImageListHidden && (
        <SImageShowWrapper ref={imageListRef}>
          {images.map(image => {
            const index = getImageIndex(images, image.url)

            return (
              <SImageWrapper
                key={index}
                onClick={() => {
                  index !== currentState && handleImageChange(index)
                }}
              >
                {index === currentState && (
                  <AnimatePresence>
                    <SActiveImageSmallOverlay
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    />
                  </AnimatePresence>
                )}
                <SImageSmall
                  src={image.url ?? ''}
                  active={index === currentState}
                />
              </SImageWrapper>
            )
          })}
        </SImageShowWrapper>
      )}
      {actionButton}
    </SWrapper>
  )
}
