import { object, string } from 'zod'
import { useDefaultZodErrorMap } from '../../../../utils/validations'
import { infer as zodInfer } from 'zod/lib/types'

export function useAuctionsDetailNoteFormValidationSchema() {
  const schema = object({
    text: string().optional()
  })
  const defaultErrorMap = useDefaultZodErrorMap()

  return {
    schema,
    errorMap: defaultErrorMap
  }
}

export type AuctionsDetailNoteFormValues = zodInfer<
  ReturnType<typeof useAuctionsDetailNoteFormValidationSchema>['schema']
>
