import styled from '@emotion/styled'
import { TextBodyBold } from 'driverama-core/components/text/Text'
import { color } from 'driverama-core/styles/variables'

export const SText = styled(TextBodyBold)`
  color: ${color('night-l-100')};
  cursor: pointer;

  &:any-link {
    color: ${color('night-l-100')};
  }

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`
