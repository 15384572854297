import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { paths } from 'driverama-core/api/driverama/generated/cars'
import { HTTPMethod } from 'driverama-core/constants/rest'
import {
  createAcceptHeaderWithVersion,
  FetcherError
} from 'driverama-core/utils/fetcher'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'

export type CarCostsResponse =
  | paths['/admin/cars/{carId}/costs']['get']['responses']['200']['content']['application/com.driverama-v2+json']
  | undefined

type QueryOpts = UseQueryOptions<unknown, unknown, CarCostsResponse>

export function useCarCostsQuery(id?: string, opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.carCosts(id),
    async () => {
      try {
        const res = await apiAuthFetcher<CarCostsResponse>(URLS.carCosts(id), {
          method: HTTPMethod.GET,
          headers: {
            Accept: createAcceptHeaderWithVersion(2)
          }
        })
        return res.json
      } catch (err) {
        if (
          err instanceof FetcherError &&
          err.response.json?.code === '113103'
        ) {
          // Car costs not found
          return {
            totalOtherCosts: 0,
            items: []
          }
        }
        throw err
      }
    },
    opts
  )
}
