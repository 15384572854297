import { operations } from 'driverama-core/api/driverama/generated/lov'
import { URLS } from 'driverama-core/constants/api'
import { QUERY_KEYS } from 'driverama-core/constants/queryKeys'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { lovQueryOptions } from './lov.utils'

export type EmissionNormsResponse = operations['getEmissionNorms']['responses']['200']['content']['application/com.driverama-v1+json']

type QueryParams = operations['getEmissionNorms']['parameters']['query']
type QueryOpts = UseQueryOptions<unknown, unknown, EmissionNormsResponse>

export async function fetchEmissionNorms(
  searchParams?: QueryParams,
  headers?: HeadersInit
) {
  const res = await apiFetcher<EmissionNormsResponse>(URLS.lovEmissionNorms, {
    searchParams,
    headers
  })

  return res.json
}

export function useEmissionNormsQuery(params?: QueryParams, opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.lovEmissionNorms(params),
    async () => fetchEmissionNorms(params),
    { ...lovQueryOptions, ...opts }
  )
}

export function useEmissionNormList() {
  const { data, ...rest } = useEmissionNormsQuery()

  const emissions = useMemo(() => {
    return (
      data?.content.map(emission => ({
        value: emission.id,
        label: emission.name ?? emission.id
      })) ?? []
    )
  }, [data])

  return { emissions, ...rest }
}
