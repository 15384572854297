import styled from '@emotion/styled'
import IconCircleCheck from 'driverama-core/images/icons/IconCircleCheck.svg'
import { color } from 'driverama-core/styles/variables'
import { LovBonusMalusWithDiff } from '../../bonusesMaluses/AuctionDetailBonusesMaluses.utils'

export const SIcon = styled(IconCircleCheck)<{
  variant: 'BONUS' | 'MALUS'
  changeType: LovBonusMalusWithDiff['changeType']
}>`
  color: ${({ variant, changeType }) =>
    changeType === 'ARRAY_ELEMENT_ADDED'
      ? color('black')
      : variant === 'BONUS'
      ? color('night-l-200')
      : color('alert')};

  opacity: ${({ changeType }) =>
    changeType === 'ARRAY_ELEMENT_REMOVED' ? 0.4 : 1};
`
