import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Dot } from 'driverama-core/components/Dot'
import { TextBody } from 'driverama-core/components/text/Text'
import { size, spaceY } from 'driverama-core/styles/spacing'
import { color, radius, weight } from 'driverama-core/styles/variables'
import { motion } from 'framer-motion'
import IconMessage from 'images/icons/IconMessageSquare.svg'

export const SContent = styled(motion.div)`
  width: 100%;
  overflow: hidden;
`

export const SImages = styled.div`
  ${spaceY(4)};
`

export const SImageContainer = styled.div`
  display: flex;

  margin: ${size(2)} 0;

  border-radius: ${radius('corner-smaller')};
  overflow: hidden;
  break-inside: avoid;

  @media print {
    display: block;
    width: 50%;
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }
`

export const SImage = styled.img`
  display: block;
  width: 100%;
  height: auto;

  @media print {
    display: none;
  }
`

export const SPrintImage = styled.img`
  display: none;

  @media print {
    display: block;
    width: 100%;
    max-width: 500px;
    height: auto;
  }
`

export const SList = styled.ul`
  ${spaceY(1)};
`

export const SListItem = styled(TextBody)<{
  changeType?: 'VALUE_ADDED' | 'VALUE_REMOVED' | 'VALUE_CHANGED'
}>`
  position: relative;
  display: flex;
  gap: ${size(2)};
  align-items: center;

  color: ${color('alert')};
  font-weight: ${weight('regular')};

  ${({ changeType }) =>
    changeType === 'VALUE_ADDED' &&
    css`
      color: ${color('black')};
    `}

  ${({ changeType }) =>
    changeType === 'VALUE_REMOVED' &&
    css`
      color: ${color('night-text-light')};
      text-decoration: line-through;
    `}
`

export const SDot = styled(Dot)`
  min-height: ${size(1)};
  min-width: ${size(1)};
  margin-left: ${size(1)};
  margin-right: ${size(1)};
`

export const SMessageIcon = styled(IconMessage)`
  width: ${size(3)};
  height: ${size(3)};
  min-height: ${size(3)};
  min-width: ${size(3)};
`
