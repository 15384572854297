import { TypedAuctionDiff } from 'api/driverama/auctions/diff'
import { CarDetailResponse } from 'api/driverama/cars/carDetail'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextSubhead } from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import { CarDetailMapped } from 'sections/auctions/detail/AuctionsDetail.utils'
import {
  ParameterKey,
  useParametersDiffs
} from '../diff/parameters/Parameters.utils'
import { ParametersItem } from '../diff/parameters/ParametersItem'
import { SGrid, SItem } from './AuctionsDetailParameters.styled'
import { parameterColumns } from './AuctionsDetailParameters.utils'

type Props = {
  mappedData: CarDetailMapped
  auctionId?: string
  car?: CarDetailResponse
  diffs: TypedAuctionDiff<ParameterKey>[]
}

export function AuctionsDetailParameters({
  mappedData,
  diffs,
  auctionId
}: Props) {
  const { t } = useTranslation(['core', 'auction'])

  const parameterDiffs = useParametersDiffs(diffs, mappedData, auctionId)

  return (
    <section>
      <TextSubhead>{t('auction:detail_parameters')}</TextSubhead>
      <Spacer axis="vertical" size={4} />
      <SGrid>
        {parameterColumns(t, mappedData, parameterDiffs).map((col, i) => (
          <SItem key={i}>
            <ParametersItem {...col} />
          </SItem>
        ))}
      </SGrid>
    </section>
  )
}
