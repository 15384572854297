import { useMemo } from 'react'
import { useSession } from '../../utils/auth'
import { decodeKeycloakJWT } from '../../utils/keycloak'

export function useRoles() {
  const { session } = useSession()
  return useMemo(() => {
    if (!session?.access_token) {
      return []
    }

    const decodedJWT = decodeKeycloakJWT(session.access_token)

    if (!decodedJWT.realm_access.roles) {
      throw new Error('Error parsing roles from JWT')
    }

    return Object.values(decodedJWT.realm_access.roles)
  }, [session])
}

export function useHasRoles<T extends string>(requestedRoles: T[]) {
  const roles = useRoles()
  return requestedRoles.reduce((acc, cur) => {
    return {
      ...acc,
      [cur]: roles.includes(cur)
    }
  }, {} as Record<T, boolean>)
}
