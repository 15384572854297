import { operations } from 'api/driverama/generated/cars'
import { useTranslation } from 'react-i18next'
import { useDefaultZodErrorMap } from 'utils/validations'
import { enum as zodEnum, number, object, string } from 'zod'

export const BID_MIN = 1
export const BID_MAX = 60000

type Currency = NonNullable<
  operations['createOrUpdateBid']['requestBody']['content']['application/json']['sellingPriceCurrencyCode']
>

export function useModalFormValidationSchema() {
  const { t } = useTranslation(['auction'])

  const currencyType = zodEnum<Currency, [Currency, ...Currency[]]>([
    'CZK',
    'EUR',
    'PLN'
  ])

  const schema = object({
    bid: number()
      .min(BID_MIN, t('auction:modal_input_min', { min: BID_MIN }))
      .max(BID_MAX, t('auction:modal_input_max', { max: BID_MAX })),
    expectedPrice: number().nullable(),
    currency: currencyType.optional(),
    partnerId: string()
  })

  const defaultErrorMap = useDefaultZodErrorMap()
  return { schema, errorMap: defaultErrorMap }
}

export function getCurrencyOptions(profileId: string | null) {
  if (profileId === process.env.NEXT_PUBLIC_BIDDER_ID) {
    return [{ label: 'EUR', value: 'EUR' }]
  }

  return [
    { label: 'EUR', value: 'EUR' },
    { label: 'CZK', value: 'CZK' },
    { label: 'PLN', value: 'PLN' }
  ]
}
