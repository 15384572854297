import { operations } from 'api/driverama/generated/lov'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { Maybe } from 'driverama-core/utils/types'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'

export type LovBodiesResponse = operations['getBodies']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryParams = operations['getBodies']['parameters']['query']

type QueryOpts = UseQueryOptions<unknown, unknown, LovBodiesResponse>

export function useLovBodiesQuery(
  searchParams?: QueryParams,
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.lovBodies(searchParams),
    async () => {
      const res = await apiFetcher<LovBodiesResponse>(URLS.lovBodies, {
        searchParams
      })

      return res.json
    },
    opts
  )
}

export function useBodyList(modelId?: Maybe<string>, makeId?: Maybe<string>) {
  const { data, ...rest } = useLovBodiesQuery({
    active: true,
    modelId: modelId ?? undefined,
    makeId: makeId ?? undefined
  })

  const bodies = useMemo(
    () =>
      data?.map(body => ({ label: body.name || body.id, value: body.id })) ||
      [],
    [data]
  )

  return { ...rest, bodies }
}
