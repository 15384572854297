import { StrikeTextBody } from 'components/diff/StrikeTextBody'
import { TextBody } from 'driverama-core/components/text/Text'
import { LovBonusMalusWithDiff } from '../../bonusesMaluses/AuctionDetailBonusesMaluses.utils'
import { SIcon } from './BonusMalusItem.styled'

export function BonusMalusItem(props: LovBonusMalusWithDiff) {
  function renderLabel() {
    if (props.changeType === 'ARRAY_ELEMENT_ADDED') {
      return (
        <>
          <TextBody color="black">{props.name}</TextBody>
        </>
      )
    }

    if (props.changeType === 'ARRAY_ELEMENT_REMOVED') {
      return <StrikeTextBody>{props.name}</StrikeTextBody>
    }

    return <TextBody>{props.name}</TextBody>
  }
  return (
    <>
      <SIcon variant={props.type} changeType={props.changeType} />
      {renderLabel()}
    </>
  )
}
