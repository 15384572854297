import { createContext, useContext } from 'react'

type AllowCloseContext = {
  allowClose: boolean
  setAllowClose: (val: boolean) => void
}

export const ModalAllowCloseContext = createContext<
  AllowCloseContext | undefined
>(undefined)

export const useModalAllowCloseContext = (): AllowCloseContext => {
  const ctx = useContext(ModalAllowCloseContext)
  if (!ctx) {
    throw new Error('Please use this hook inside Modal component.')
  }

  return ctx
}

type ModalCloseContext = () => void

export const ModalCloseContext = createContext<ModalCloseContext | undefined>(
  undefined
)

export const useCloseModalContext = () => {
  const ctx = useContext(ModalCloseContext)
  if (!ctx) {
    throw new Error('Please use this hook inside Modal component.')
  }

  return ctx
}

export const useCloseModalContextOptional = (enabled = true) => {
  const ctx = useContext(ModalCloseContext)
  if (enabled && !ctx) {
    throw new Error('Please use this hook inside Modal component.')
  }

  return ctx
}
