import crypto from 'crypto'
import { base64urlDecode } from './jwt'

export type DecodedKeycloakToken = {
  acr: string
  aud: string
  auth_time: number
  azp: string
  realm_access: {
    roles: Record<number, string>
  }
}

export function decodeKeycloakJWT(
  token: string,
  noVerify?: boolean
): DecodedKeycloakToken {
  const cert = Buffer.from(
    process.env.NEXT_PUBLIC_KEYCLOAK_PUBLIC_KEY as string,
    'base64'
  ).toString('utf8')

  // check segments
  const segments = token.split('.')
  if (segments.length !== 3) {
    throw new Error('Not enough or too many segments')
  }

  // All segment should be base64
  const rawHead = segments[0]
  const rawBody = segments[1]
  const signature = segments[2]

  if (!noVerify) {
    const verifyObject = crypto.createVerify('RSA-SHA256')
    verifyObject.write(rawHead + '.' + rawBody)
    verifyObject.end()

    if (!verifyObject.verify(cert, signature, 'base64')) {
      throw new Error('Signature verification failed')
    }
  }

  return JSON.parse(base64urlDecode(rawBody))
}
