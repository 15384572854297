import { useBranchDetail } from 'driverama-core/api/driverama/lov/lovBranchesSearch'
import { Spinner } from 'driverama-core/components/spinner/Spinner'
import { TextBodyBold } from 'driverama-core/components/text/Text'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'

interface Props {
  branchId?: string | null
}

export function LocationBranch(props: Props) {
  const { t } = useTranslation(['core'])
  const { branch, isError, isLoading } = useBranchDetail(props.branchId)

  if (isLoading) {
    return <Spinner variant="x-small" />
  }

  if (isError) {
    return <TextBodyBold>{t('core:error_api_title')}</TextBodyBold>
  }

  if (branch) {
    return (
      <Link passHref href={branch.navigationUrl || ''}>
        <a
          target="_blank"
          rel="noreferrer"
          title={`${branch.fullAddress} - ${branch.id}`}
        >
          <TextBodyBold>{branch.name}</TextBodyBold>
        </a>
      </Link>
    )
  }

  return null
}
