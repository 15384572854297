import styled from '@emotion/styled'
import { Button } from 'driverama-core/components/button/Button'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, radius, shadow, weight } from 'driverama-core/styles/variables'

export const SExportButton = styled(Button)`
  position: absolute;
  right: ${size(10)};
  top: ${size(8)};

  @media ${media.lte('laptop')} {
    top: ${size(9)};
    padding: ${size(3)} ${size(4)};
  }

  @media ${media.lte('tablet')} {
    display: none;
  }
`

export const SExportButtonMobile = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: ${size(2)} ${size(4)};
  border-radius: ${radius('corner')};
  box-shadow: ${shadow(3)};

  font-size: 14px;

  width: ${size(26)};

  color: ${color('night-l-100')};
  font-weight: ${weight('bold')};

  @media print {
    display: none;
  }
`
