import { StrikeTextBodyBold } from 'components/diff/StrikeTextBodyBold'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBodyBold, TextHeader } from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'

type Props = {
  total: number
  diffTotal: number
}

export function RepairCostTotal({ total, diffTotal }: Props) {
  const { t } = useTranslation(['auction'])

  return (
    <>
      <TextHeader variant="h6" as="h6">
        {t('auction:detail_repair_costs_total')}
      </TextHeader>

      <Flex variant="row">
        {total !== diffTotal && (
          <>
            <StrikeTextBodyBold>
              {t('auction:price', { price: diffTotal })}
            </StrikeTextBodyBold>{' '}
            <Spacer size={2} axis="horizontal" />
          </>
        )}

        <TextBodyBold>{t('auction:price', { price: total })}</TextBodyBold>
      </Flex>
    </>
  )
}
