import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Chip } from 'driverama-core/components/chip/Chip'
import { TextBody } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'

export const SChip = styled(Chip)<{
  selected?: boolean
}>`
  padding: ${size(2)} ${size(7.5)};
  border: 2px solid
    ${({ selected }) =>
      selected ? color('night-l-100') : color('night-l-650')};
  background: ${({ selected }) =>
    selected ? color('night-l-100') : 'transparent'};
  box-sizing: border-box;
  min-width: 130px;

  ${({ selected }) =>
    selected &&
    css`
      ${TextBody} {
        color: ${color('white')};
      }
    `}

  @media ${media.lte('mobile')} {
    flex: 1;
  }
`

export const SChipWrapper = styled.div<{ auctionHasBid: boolean }>`
  position: relative;
  padding: ${size(10)} ${size(4)};

  transition: padding 300ms ease-in-out;

  ${({ auctionHasBid }) =>
    auctionHasBid &&
    css`
      @media ${media.lte('tablet')} {
        padding-top: ${size(24)};
      }
    `}

  @media print {
    display: none;
  }
`
