import styled from '@emotion/styled'
import { color, radius } from 'driverama-core/styles/variables'
import { size } from 'driverama-core/styles/spacing'
import { TextSubhead } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'

export const SCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${size(80)}, 1fr));
  grid-column-gap: ${size(4)};

  @media ${media.lte('tablet')} {
    grid-row-gap: ${size(2)};
    grid-template-columns: 1fr;
  }
`

export const SCard = styled.div`
  padding: ${size(4)};

  background-color: ${color('white')};
  border-radius: ${radius('corner')};
`

export const SLabel = styled(TextSubhead)`
  color: ${color('night-text')};
`
