import { AuctionDiff, TypedAuctionDiff } from 'api/driverama/auctions/diff'
import { unique } from 'driverama-core/utils/array'
import { isNotNil } from 'driverama-core/utils/types'
import { CarDetailMapped } from '../../AuctionsDetail.utils'

const WHEEL_ITEM_ID_REGEX = /\[(.*?)\]/

type Wheel = NonNullable<CarDetailMapped['wheels']>[number]

type ExtraWheel = Omit<Wheel, 'position'> & {
  position:
    | 'EXTRA_FRONT_LEFT'
    | 'EXTRA_FRONT_RIGHT'
    | 'EXTRA_REAR_LEFT'
    | 'EXTRA_REAR_RIGHT'
}

const extraPositions: ExtraWheel['position'][] = [
  'EXTRA_FRONT_LEFT',
  'EXTRA_FRONT_RIGHT',
  'EXTRA_REAR_LEFT',
  'EXTRA_REAR_RIGHT'
]

export type WheelItemDiff = Wheel & {
  id: string
  changeType: Extract<
    AuctionDiff['changeType'],
    'VALUE_ADDED' | 'VALUE_REMOVED'
  >
}

type ExtraWheelItemDiff = ExtraWheel & {
  id: string
  changeType: Extract<
    AuctionDiff['changeType'],
    'VALUE_ADDED' | 'VALUE_REMOVED'
  >
}

function isExtraWheel(
  wheel: WheelItemDiff | ExtraWheelItemDiff
): wheel is ExtraWheelItemDiff {
  return extraPositions.some(p => p === wheel.position)
}

export function getWheelDiffArray(diffs: AuctionDiff[]): WheelItemDiff[] {
  const wheelIds = unique(
    diffs
      .map(diff => diff.propertyName.match(WHEEL_ITEM_ID_REGEX)?.[1])
      .filter(isNotNil)
  )

  const wheelsDiffs = wheelIds.map(id =>
    diffs
      .filter(diff => diff.propertyName.includes(id))
      .reduce((acc, curr) => {
        if (
          curr.changeType === 'VALUE_ADDED' ||
          curr.changeType === 'VALUE_REMOVED'
        ) {
          const propertyNameParts = curr.propertyName.split('.')
          const attribute = propertyNameParts[propertyNameParts.length - 1]
          const value =
            attribute === 'tireTreadDepthMm'
              ? Number(curr.oldValue ?? '0')
              : curr.changeType === 'VALUE_ADDED'
              ? curr.newValue
              : curr.oldValue

          return {
            ...acc,
            [attribute]: value,
            changeType: curr.changeType
          }
        }

        return acc
      }, {} as WheelItemDiff)
  )

  return wheelsDiffs
}

export const getExtraSetOfTiresDiff = (
  wheelDiffs: WheelItemDiff[]
): TypedAuctionDiff<'$.wheels.extraSetOfTires'> | undefined => {
  if (!!wheelDiffs.length) {
    const hasExtraSetOfTires = wheelDiffs.some(
      diff => diff.changeType === 'VALUE_ADDED' && isExtraWheel(diff)
    )

    const hasNotExtraSetOfTires = wheelDiffs.some(
      diff => diff.changeType === 'VALUE_REMOVED' && isExtraWheel(diff)
    )

    if (hasExtraSetOfTires) {
      return {
        propertyName: '$.wheels.extraSetOfTires',
        changeType: 'VALUE_CHANGED',
        oldValue: 'false'
      }
    }

    if (hasNotExtraSetOfTires) {
      return {
        propertyName: '$.wheels.extraSetOfTires',
        changeType: 'VALUE_CHANGED',
        oldValue: 'true'
      }
    }
  }

  return undefined
}

export function useExtraSetOfTiresDiff(
  auctionDiffs: AuctionDiff[]
): TypedAuctionDiff<'$.wheels.extraSetOfTires'> | undefined {
  const wheelDiffs = getWheelDiffArray(auctionDiffs)

  return getExtraSetOfTiresDiff(wheelDiffs)
}
