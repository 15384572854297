import styled from '@emotion/styled'
import { Button } from 'driverama-core/components/button/Button'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'

export const SEditButton = styled(Button)`
  position: absolute;
  right: 0;
  top: ${size(-1)};
  padding: ${size(1)} ${size(4)};

  font-size: ${size(3)};

  @media ${media.gt('MD')} {
    top: ${size(-2)};
  }
`
