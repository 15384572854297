import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { TextBody } from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import { AuctionsDetailTechnicalItem } from 'sections/auctions/detail/technical/item/AuctionsDetailTechnicalItem'
import { SItems } from './AuctionsDetailTechnicalItems.styled'
import { useCategories } from './AuctionsDetailTechnicalItems.utils'

interface Props {
  carId: string
  auctionId: string | undefined
  sectionId: string
  onDetailExpand: (name: string) => void
}

export function AuctionsDetailTechnicalItems({
  carId,
  auctionId,
  sectionId,
  onDetailExpand
}: Props) {
  const { t } = useTranslation(['core'])
  const { data, isLoading, isError } = useCategories(carId, sectionId)

  return isLoading ? (
    <SpinnerCentered />
  ) : isError || !data ? (
    <TextBody>{t('core:error_api')}</TextBody>
  ) : (
    <SItems>
      {data.map(category => (
        <AuctionsDetailTechnicalItem
          onDetailExpand={onDetailExpand}
          key={category.categoryId}
          carId={carId}
          auctionId={auctionId}
          title={category.name || ''}
          {...category}
        />
      ))}
    </SItems>
  )
}
