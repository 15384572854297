import { operations } from 'api/driverama/generated/lov'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import {
  CarBonusesMalusesResponse,
  useCarBonusesMaluses
} from '../cars/carBonusesMaluses'

export type LovBonusesMalusesResponse = operations['getBonusesMaluses']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryParams = operations['getBonusesMaluses']['parameters']['query']
type QueryOpts = UseQueryOptions<unknown, unknown, LovBonusesMalusesResponse>

export function useLovBonusesMalusesQuery(
  searchParams?: QueryParams,
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.lovBonusesMaluses(searchParams),
    async () => {
      const res = await apiFetcher<LovBonusesMalusesResponse>(
        URLS.lovBonusesMaluses,
        {
          searchParams
        }
      )

      return res.json
    },
    opts
  )
}

type QueryOptsList = UseQueryOptions<
  unknown,
  unknown,
  CarBonusesMalusesResponse
>

export type BonusMalus = LovBonusesMalusesResponse['content'][number]

export function useBonusesMalusesList(carId?: string, opts?: QueryOptsList) {
  const carBonusesMalusesQuery = useCarBonusesMaluses(carId, opts)

  const lovBonusesMalusesQuery = useLovBonusesMalusesQuery()

  const queries = [carBonusesMalusesQuery, lovBonusesMalusesQuery]

  const bonusesMalusesList = useMemo(() => {
    const list = lovBonusesMalusesQuery.data?.content || []
    return list.filter(item => carBonusesMalusesQuery.data?.includes(item.id))
  }, [lovBonusesMalusesQuery.data?.content, carBonusesMalusesQuery.data])

  const bonuses = bonusesMalusesList.filter(item => item.type === 'BONUS')
  const maluses = bonusesMalusesList.filter(item => item.type === 'MALUS')

  return {
    isLoading: queries.find(q => q.isLoading),
    isError: queries.find(q => q.isError),
    bonuses,
    maluses
  }
}
