import { Divider } from 'driverama-core/components/divider/Divider'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody } from 'driverama-core/components/text/Text'
import { Flex } from 'driverama-core/components/Flex'
import { PropsWithChildren } from 'react'

export function AuctionsDetailTechnicalDivider({
  children
}: PropsWithChildren<unknown>) {
  return (
    <>
      <Spacer axis="vertical" size={6} />
      <Flex variant="row" align="center">
        <Divider variant="dark" />
        <Spacer axis="horizontal" size={2} />
        <TextBody variant="setup">{children}</TextBody>
        <Spacer axis="horizontal" size={2} />
        <Divider variant="dark" />
      </Flex>
      <Spacer axis="vertical" size={6} />
    </>
  )
}
