import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { paths } from 'driverama-core/api/driverama/generated/lov'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { useQuery, UseQueryOptions } from 'react-query'

type LovStandardRepairsSearchResponse = paths['/lov/standard-repairs/search']['post']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryBody = paths['/lov/standard-repairs/search']['post']['requestBody']['content']['application/json']

type QueryOpts = UseQueryOptions<
  unknown,
  unknown,
  LovStandardRepairsSearchResponse
>

export function useLovStandardRepairsSearchQuery(
  body: QueryBody,
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.lovStandardRepairs(body),
    async () => {
      const res = await apiFetcher<LovStandardRepairsSearchResponse>(
        URLS.lovStandardRepairs,
        { method: HTTPMethod.POST, body }
      )

      return res.json
    },
    opts
  )
}
