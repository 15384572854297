import { TagVariant } from 'driverama-core/utils/tags'
import { useTranslation } from 'react-i18next'
import { AuctionState } from 'sections/auctions/detail/AuctionsDetail.utils'
export function useAuctionState(
  state: AuctionState
): {
  label: string
  variant: TagVariant
} | null {
  const { t } = useTranslation('auction')

  switch (state) {
    case 'READY_TO_START':
      return {
        label: t('state_READY_TO_START'),
        variant: 'secondary'
      }

    case 'IN_PROGRESS':
      return {
        label: t('state_IN_PROGRESS'),
        variant: 'secondary'
      }

    case 'SUCCESSFUL':
      return {
        label: t('state_SUCCESSFUL'),
        variant: 'primary'
      }

    case 'SUCCESSFUL_WINNER_CHANGED':
      return {
        label: t('state_SUCCESSFULL_WINNER_CHANGED'),
        variant: 'primary'
      }

    case 'FAILED':
      return {
        label: t('state_FAILED'),
        variant: 'warning-secondary'
      }

    case 'CANCELLED':
      return {
        label: t('state_CANCELLED'),
        variant: 'warning-secondary'
      }

    default:
      return null
  }
}
