import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { paths } from 'driverama-core/api/driverama/generated/cars'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'

type CarTechnicalCategoriesResponse =
  | paths['/admin/cars/technical-condition-categories/search-agg']['post']['responses']['200']['content']['application/com.driverama-v2+json']
  | undefined
type CarTechnicalCategoriesBody = paths['/admin/cars/technical-condition-categories/search-agg']['post']['requestBody']['content']['application/json']

type QueryOpts = UseQueryOptions<
  unknown,
  unknown,
  CarTechnicalCategoriesResponse
>

export type CarTechnicalCategory = paths['/admin/cars/technical-condition-categories/search-agg']['post']['responses']['200']['content']['application/com.driverama-v2+json']['categories'][number]

export function useCarTechnicalCategories(
  body?: CarTechnicalCategoriesBody,
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.carTechnicalCategories(body),
    async () => {
      const res = await apiAuthFetcher<CarTechnicalCategoriesResponse>(
        URLS.carTechnicalCategories,
        {
          method: HTTPMethod.POST,
          headers: {
            Accept: 'application/com.driverama-v2+json'
          },
          body
        }
      )
      return res.json
    },
    opts
  )
}
