import { TypedAuctionDiff } from 'api/driverama/auctions/diff'
import { TFunction, useTranslation } from 'react-i18next'
import { CarDetailMapped } from 'sections/auctions/detail/AuctionsDetail.utils'

export type AccidentsDiffTypes = TypedAuctionDiff<
  | '$.accident.front'
  | '$.accident.rear'
  | '$.accident.left'
  | '$.accident.right'
  | '$.accident.roof'
>[]

type AccidentState =
  | 'NONE'
  | 'PAINT'
  | 'LIGHT'
  | 'MEDIUM'
  | 'BAD'
  | undefined
  | null

const accidentState = (
  accident: AccidentState,
  t: TFunction<('auction' | 'core')[]>
) => {
  switch (accident) {
    case 'PAINT':
      return t('auction:detail_parameters_accident_paint')
    case 'LIGHT':
      return t('auction:detail_parameters_accident_light')
    case 'MEDIUM':
      return t('auction:detail_parameters_accident_medium')
    case 'BAD':
      return t('auction:detail_parameters_accident_bad')
    case 'NONE':
      return t('auction:detail_parameters_accident_none')
    default:
      return t('core:not_yet_detected')
  }
}

export function useAccidentDiffs(diffs: AccidentsDiffTypes) {
  const { t } = useTranslation(['core', 'auction'])

  return diffs.map(diff => ({
    ...diff,
    oldValue: accidentState(diff.oldValue as AccidentState, t)
  }))
}

type ParameterColumnsResponse = {
  label: string
  state?: 'NONE' | 'PAINT' | 'LIGHT' | 'MEDIUM' | 'BAD'
  value: number | string | undefined
  diff?: AccidentsDiffTypes[number]
}[]

export const parameterColumns = (
  t: TFunction<('auction' | 'core')[]>,
  data: CarDetailMapped,
  diffs?: AccidentsDiffTypes
): ParameterColumnsResponse => {
  return [
    {
      label: t('auction:detail_parameters_accident_front'),
      state: data.accident?.front,
      value: accidentState(data.accident?.front, t),
      diff: diffs?.find(diff => diff.propertyName === '$.accident.front')
    },
    {
      label: t('auction:detail_parameters_accident_rear'),
      state: data.accident?.rear,
      value: accidentState(data.accident?.rear, t),
      diff: diffs?.find(diff => diff.propertyName === '$.accident.rear')
    },
    {
      label: t('auction:detail_parameters_accident_left'),
      state: data.accident?.left,
      value: accidentState(data.accident?.left, t),
      diff: diffs?.find(diff => diff.propertyName === '$.accident.left')
    },
    {
      label: t('auction:detail_parameters_accident_right'),
      state: data.accident?.right,
      value: accidentState(data.accident?.right, t),
      diff: diffs?.find(diff => diff.propertyName === '$.accident.right')
    },
    {
      label: t('auction:detail_parameters_accident_roof'),
      state: data.accident?.roof,
      value: accidentState(data.accident?.roof, t),
      diff: diffs?.find(diff => diff.propertyName === '$.accident.roof')
    }
  ]
}
