import styled from '@emotion/styled'
import IconCircleCheck from 'driverama-core/images/icons/IconCircleCheck.svg'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'

export const SContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${size(10)};

  @media ${media.lte('mobile')} {
    grid-template-columns: 1fr;
    gap: ${size(5)};
  }
`

export const SList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${size(3)};
`

export const SItem = styled.li`
  display: flex;
  align-items: center;
  gap: ${size(2)};
`

export const SIcon = styled(IconCircleCheck)<{ variant: 'bonus' | 'malus' }>`
  flex-shrink: 0;
  width: ${size(5)};
  height: ${size(5)};
  color: ${({ variant }) =>
    variant === 'bonus' ? color('night-l-200') : color('alert')};
`
