import { AuctionDiff } from 'api/driverama/auctions/diff'
import { Flex } from 'driverama-core/components/Flex'
import { SCount, SNumber } from './AuctionsDetailTechnicalItem.styled'

type Props = {
  problematicCount: number
  problematicCountDiff?: AuctionDiff
}

export function ProblematicItemsCount({
  problematicCount,
  problematicCountDiff
}: Props) {
  return (
    <Flex variant="row" gap={1}>
      {!!problematicCountDiff?.oldValue && (
        <SCount isDiff>
          <SNumber size="small">{problematicCountDiff.oldValue}</SNumber>
        </SCount>
      )}

      <SCount
        hasAdded={
          !!problematicCountDiff?.oldValue
            ? parseFloat(problematicCountDiff.oldValue) < problematicCount
            : false
        }
      >
        <SNumber size="small">{problematicCount}</SNumber>
      </SCount>
    </Flex>
  )
}
