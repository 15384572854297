import styled from '@emotion/styled'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, radius } from 'driverama-core/styles/variables'

export const SContainer = styled.div`
  width: ${size(248)};
  margin: ${size(10)} auto;
  background: ${color('white')};
  padding: ${size(6)};
  border-radius: ${radius('corner')};

  @media ${media.lte('tablet')} {
    width: calc(100% - ${size(8)});
    padding: ${size(8)} ${size(4)} ${size(6)};
    margin: 0 auto;
  }
`

export const SSpinner = styled(SpinnerCentered)`
  height: 100%;
`
