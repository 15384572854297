import styled from '@emotion/styled'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'

export const SItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${size(4)};
  align-items: flex-start;
  break-inside: avoid;

  @media ${media.lte('tablet')} {
    grid-template-columns: 1fr;
    grid-gap: ${size(2)};
  }
`
