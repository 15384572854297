import { operations } from 'api/driverama/generated/lov'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'

export type LovCarFeatureSectionsResponse = operations['getCarFeatureSections']['responses']['200']['content']['application/com.driverama-v1+json']
type SectionsQueryParams = operations['getCarFeatureSections']['parameters']['query']
type SectionsQueryOpts = UseQueryOptions<
  unknown,
  unknown,
  LovCarFeatureSectionsResponse
>

export function useLovCarFeatureSectionsQuery(
  searchParams?: SectionsQueryParams,
  opts?: SectionsQueryOpts
) {
  return useQuery(
    QUERY_KEYS.lovCarFeaturesSections(searchParams),
    async () => {
      const res = await apiFetcher<LovCarFeatureSectionsResponse>(
        URLS.lovCarFeaturesSections,
        { searchParams }
      )

      return res.json
    },
    opts
  )
}

export function useLovCarFeatureSectionList() {
  const { data, ...rest } = useLovCarFeatureSectionsQuery({ active: true })

  const sections = useMemo(() => {
    return (
      data?.content.map(section => ({
        id: section.id,
        name: section.name
      })) || []
    )
  }, [data])

  return { ...rest, sections }
}

export type LovCarFeatureCategoriesResponse = operations['getCarFeatureCategories']['responses']['200']['content']['application/com.driverama-v1+json']
type CategoriesQueryParams = operations['getCarFeatureCategories']['parameters']['query']
type CategoriesQueryOpts = UseQueryOptions<
  unknown,
  unknown,
  LovCarFeatureCategoriesResponse
>

export function useLovCarFeatureCategoriesQuery(
  searchParams?: CategoriesQueryParams,
  opts?: CategoriesQueryOpts
) {
  return useQuery(
    QUERY_KEYS.lovCarFeaturesCategories(searchParams),
    async () => {
      const res = await apiFetcher<LovCarFeatureCategoriesResponse>(
        URLS.lovCarFeaturesCategories,
        { searchParams }
      )

      return res.json
    },
    opts
  )
}

export function useLovCarFeatureCategoryList(sectionIds?: string[]) {
  const { data, ...rest } = useLovCarFeatureCategoriesQuery({
    active: true,
    sectionIds
  })

  const categories = useMemo(() => {
    return (
      data?.content.map(category => ({
        id: category.id,
        name: category.name,
        sectionId: category.sectionId
      })) || []
    )
  }, [data])

  return { ...rest, categories }
}

export type LovCarFeaturesResponse = operations['getCarFeatureItems']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryBody = operations['getCarFeatureItems']['requestBody']['content']['application/json']
type QueryOpts = UseQueryOptions<unknown, unknown, LovCarFeaturesResponse>

export type LovFeature = LovCarFeaturesResponse['content'][0]

export function useLovCarFeaturesSearchQuery(
  body: QueryBody = {},
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.lovCarFeaturesSearch(body),
    async () => {
      const res = await apiFetcher<LovCarFeaturesResponse>(
        URLS.lovCarFeaturesSearch,
        { method: HTTPMethod.POST, body }
      )

      return res.json
    },
    opts
  )
}

export function useCarFeatureList(categoryIds?: string[], opts?: QueryOpts) {
  const { data, ...rest } = useLovCarFeaturesSearchQuery(
    {
      filter: {
        ids: [],
        categoryIdIncludeNull: false,
        categoryIds: categoryIds ?? [],
        active: true
      }
    },
    opts
  )

  const features = useMemo(() => {
    return (
      data?.content.map(feature => ({
        id: feature.id,
        name: feature.name,
        active: feature.active,
        categoryId: feature.categoryId
      })) || []
    )
  }, [data])

  return { ...rest, features }
}
