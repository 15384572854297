import { css } from '@emotion/react'
import { AuctionSearchResponseAgg } from 'api/driverama/auctions/searchAuctionsAgg'
import { AuctionStateTag } from 'components/auction/AuctionStateTag'
import { LINKS } from 'constants/links'
import { format } from 'date-fns'
import { Dot } from 'driverama-core/components/Dot'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { Tag } from 'driverama-core/components/tag/Tag'
import { TextLink } from 'driverama-core/components/text/link/TextLink'
import {
  TextBody,
  TextBodyBold,
  TextSubhead
} from 'driverama-core/components/text/Text'
import { Tooltip } from 'driverama-core/components/tooltip/Tooltip'
import IconInfo from 'driverama-core/images/icons/IconCircleInfo.svg'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { parseUTCDate } from 'driverama-core/utils/date'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMedia } from 'react-use'
import { SGrid, SItem, SShowMoreButton } from './AuctionDetailRelated.styled'

interface Props {
  auctions: AuctionSearchResponseAgg['content']
  title: string
  tooltip?: string
}

const DEFAULT_LIST_SIZE = 2

export function AuctionsDetailRelated(props: Props) {
  const { auctions, title, tooltip } = props
  const { t } = useTranslation(['auction', 'history'])

  const isMobile = useMedia(media.lte('mobile'))

  const [showMore, setShowMore] = useState(false)

  return (
    <section>
      <TextSubhead>
        {title}
        {tooltip && (
          <Tag variant="tertiary">
            <Tooltip
              content={tooltip}
              placement="top"
              maxWidth="360px"
              css={css`
                margin-left: ${size(2)};
              `}
            >
              <IconInfo
                css={css`
                  margin-left: -${size(3)};
                  width: ${size(4)};
                  height: ${size(4)};
                  vertical-align: text-bottom;
                `}
              />
            </Tooltip>
          </Tag>
        )}
      </TextSubhead>
      <Spacer axis="vertical" size={4} />

      <SGrid>
        {auctions
          .slice(0, showMore ? auctions.length : DEFAULT_LIST_SIZE)
          .map(auction => {
            return (
              <SItem key={auction.id}>
                <div
                  css={css`
                    display: flex;
                    gap: ${size(2)};
                    align-items: center;
                  `}
                >
                  <TextBody>
                    {format(
                      parseUTCDate(auction.createdAt),
                      'dd/MM/yyyy HH:mm'
                    )}
                  </TextBody>

                  <Dot />
                  {auction?.state && <AuctionStateTag state={auction.state} />}
                </div>
                <TextLink href={LINKS.historyDetail(auction.id)}>
                  {isMobile
                    ? t('auction:show_auction_mobile')
                    : t('auction:show_auction')}
                </TextLink>
              </SItem>
            )
          })}
      </SGrid>

      {auctions.length > DEFAULT_LIST_SIZE && (
        <div
          css={css`
            text-align: center;
          `}
        >
          <Spacer axis="vertical" size={[2, 2, 4]} />
          <SShowMoreButton onClick={() => setShowMore(state => !state)}>
            <TextBodyBold color="white">
              {showMore ? t('auction:show_less') : t('auction:show_more')}
            </TextBodyBold>
          </SShowMoreButton>
        </div>
      )}
    </section>
  )
}
