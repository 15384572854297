import { useCarTechnicalSectionsQuery } from 'api/driverama/cars/carTechnicalSections'
import { useLovTechnicalSectionsQuery } from 'api/driverama/lov/lovTechnicalSections'
import { useMemo } from 'react'

export function useSections(carId: string) {
  const opts = { enabled: !!carId, refetchOnWindowFocus: false }

  const sections = useCarTechnicalSectionsQuery({ carId }, opts)
  const lovSections = useLovTechnicalSectionsQuery(undefined, opts)

  const data = useMemo(() => {
    if (!!sections.data && lovSections.data) {
      return sections.data.sections
        .sort((a, b) => a.sectionId.localeCompare(b.sectionId))
        .map(section => {
          const lovSection = lovSections.data.content.find(
            item => item.id === section.sectionId
          )
          return {
            sectionId: section.sectionId,
            lovSectionId: lovSection?.id,
            name: lovSection?.name
          }
        })
    }
    return undefined
  }, [lovSections.data, sections.data])

  return {
    data,
    isLoading: sections.isLoading || lovSections.isLoading,
    isError: sections.isError || lovSections.isError
  }
}
