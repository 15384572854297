import { css } from '@emotion/react'
import { useCarTechnicalSubCategories } from 'api/driverama/cars/carTechnicalSubcategories'
import { useLovTechnicalSubCategoriesQuery } from 'api/driverama/lov/lovTechnicalSubCategories'
import { Divider } from 'driverama-core/components/divider/Divider'
import { ImagesGallery } from 'driverama-core/components/imagesGallery/ImagesGallery'
import {
  imageGalleryModalBackgroundStyles,
  imageGalleryModalStyles
} from 'driverama-core/components/imagesGallery/ImagesGalleryModalStyles'
import { useModal } from 'driverama-core/components/modal/Modal'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { TextBody } from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import { logEvent } from 'utils/analytics'
import { SContent, SList } from './AuctionsDetailTechnicalItemContent.styled'
import { useTechnicalItems } from './AuctionsDetailTechnicalItemContent.utils'
import { DamagedItem } from './DamagedItem'

interface Props {
  carId: string
  auctionId: string | undefined
  categoryId: string
}

export function AuctionsDetailTechnicalItemContent({
  carId,
  auctionId,
  categoryId
}: Props) {
  const { t } = useTranslation(['core'])

  const subCategories = useCarTechnicalSubCategories(
    { carId, categoryId },
    { refetchOnWindowFocus: false }
  )
  const lovSubCategories = useLovTechnicalSubCategoriesQuery(
    { filter: { categoryIds: [categoryId], ids: [] } },
    undefined,
    { refetchOnWindowFocus: false }
  )
  const items = useTechnicalItems(carId, lovSubCategories.data)

  const isLoading =
    subCategories.isLoading || lovSubCategories.isLoading || items.isLoading
  const isError =
    subCategories.isError || lovSubCategories.isError || items.isError

  const subCategoriesWithProblems = lovSubCategories.data?.content.filter(
    lovSubCategory =>
      items.data?.some(item => item.subCategoryId === lovSubCategory.id)
  )

  const itemsFromSubCategory = (id: string) =>
    items.data?.filter(item => item.subCategoryId === id)

  const logImageView = () => {
    logEvent('photo_viewed', { auction_id: auctionId })
  }

  const allPhotos =
    items.data?.reduce((acc: { url: string }[], cur) => {
      return [
        ...acc,
        ...cur.photos.map(photo => ({
          url: photo.url
        }))
      ]
    }, []) ?? []

  const [modal, openModal] = useModal(
    () => (
      <ImagesGallery
        logImageView={logImageView}
        images={allPhotos}
        showFullImg
        isImageListHidden
      />
    ),
    {
      wrapperStyles: imageGalleryModalStyles,
      backgroundStyles: imageGalleryModalBackgroundStyles,
      closeLabel: t('core:close')
    }
  )

  return (
    <>
      <SContent
        variants={{
          open: { height: 'auto' },
          collapsed: { height: 0 }
        }}
        initial="collapsed"
        animate="open"
        exit="collapsed"
        transition={{ duration: 0.4, ease: 'easeInOut' }}
      >
        <Spacer axis="vertical" size={6} />
        <Divider />
        <Spacer axis="vertical" size={4} />
        {isLoading ? (
          <SpinnerCentered variant="small" />
        ) : isError ? (
          <TextBody>{t('core:error_api')}</TextBody>
        ) : (
          <SList>
            {subCategoriesWithProblems?.map(subCategory => {
              const items = itemsFromSubCategory(subCategory.id) ?? []

              const hasAddedItems = items?.some(
                item => item.diff?.changeType === 'VALUE_ADDED'
              )

              const isRemovedSubcategory = items.every(
                item => item.diff?.changeType === 'VALUE_REMOVED'
              )

              return (
                <div key={subCategory.id}>
                  <TextBody
                    color={
                      hasAddedItems
                        ? 'black'
                        : isRemovedSubcategory
                        ? 'night-text-light'
                        : 'alert'
                    }
                    css={css`
                      text-decoration: ${isRemovedSubcategory &&
                      'line-through'};
                    `}
                  >
                    {subCategory.name}
                  </TextBody>

                  {items?.map(item => (
                    <DamagedItem
                      key={`${subCategory.name}.${item.name}`}
                      item={item}
                      openModal={openModal}
                    />
                  ))}
                </div>
              )
            })}
          </SList>
        )}
      </SContent>
      {modal}
    </>
  )
}
