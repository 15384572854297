import { operations } from 'api/driverama/generated/lov'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { useQuery, UseQueryOptions } from 'react-query'

export type LovTechnicalSectionsResponse = operations['getSections']['responses']['200']['content']['application/com.driverama-v1+json']
type LovTechnicalSectionsParams = operations['getSections']['parameters']['query']
type LovTechnicalSectionsOpts = UseQueryOptions<
  unknown,
  unknown,
  LovTechnicalSectionsResponse
>

export function useLovTechnicalSectionsQuery(
  searchParams?: LovTechnicalSectionsParams,
  opts?: LovTechnicalSectionsOpts
) {
  return useQuery(
    QUERY_KEYS.lovTechnicalSections(searchParams),
    async () => {
      const res = await apiFetcher<LovTechnicalSectionsResponse>(
        URLS.lovTechnicalSections,
        { searchParams }
      )
      return res.json
    },
    opts
  )
}
