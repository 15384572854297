import { TypedAuctionDiff } from 'api/driverama/auctions/diff'
import { useBonusesMalusesList } from 'api/driverama/lov/lovBonusesMaluses'
import { ErrorApi } from 'driverama-core/components/errorApi/ErrorApi'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import {
  TextBody,
  TextHeader,
  TextSubhead
} from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import { BonusMalusItem } from '../diff/bonusesMaluses/BonusMalusItem'
import { SContainer, SItem, SList } from './AuctionDetailBonusesMaluses.styled'
import { useBonusesMalusesWithDiffs } from './AuctionDetailBonusesMaluses.utils'

interface Props {
  carId?: string
  diffs?: TypedAuctionDiff<'$.bonusesMaluses'>[]
}

export function AuctionDetailBonusesMaluses(props: Props) {
  const { t } = useTranslation(['core', 'auction'])
  const { bonuses, maluses, isError, isLoading } = useBonusesMalusesList(
    props.carId,
    {
      enabled: !!props.carId
    }
  )

  const { bonusesWithDiff, malusesWithDiff } = useBonusesMalusesWithDiffs(
    bonuses,
    maluses,
    props.diffs ?? []
  )

  if (isLoading) {
    return <SpinnerCentered />
  }

  if (isError) {
    return (
      <ErrorApi
        title={t('core:error_api_title')}
        message={t('core:error_api_message')}
      />
    )
  }

  function renderContent() {
    if (bonusesWithDiff.length === 0 && malusesWithDiff.length === 0) {
      return <TextBody>{t('auction:detail_no_bonuses_maluses')}</TextBody>
    }

    return (
      <SContainer>
        <Flex variant="column" gap={3}>
          <TextHeader as="h6">{t('auction:detail_bonuses')}</TextHeader>
          <SList>
            {bonusesWithDiff.map(bonus => (
              <SItem key={bonus.id}>
                <BonusMalusItem {...bonus} />
              </SItem>
            ))}
          </SList>
        </Flex>

        <Flex variant="column" gap={3}>
          <TextHeader as="h6">{t('auction:detail_maluses')}</TextHeader>
          <SList>
            {malusesWithDiff.map(malus => (
              <SItem key={malus.id}>
                <BonusMalusItem {...malus} />
              </SItem>
            ))}
          </SList>
        </Flex>
      </SContainer>
    )
  }

  return (
    <section>
      <TextSubhead>{t('auction:detail_bonuses_maluses')}</TextSubhead>
      <Spacer axis="vertical" size={6} />

      {renderContent()}
    </section>
  )
}
