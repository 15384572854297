import { zodResolver } from '@hookform/resolvers/zod'
import { useAuctionWinnerMutation } from 'api/driverama/auctions/changeAuctionWinner'
import { OpportunityState } from 'driverama-core/api/driverama/opportunities/opportunityDetail'
import { useHasRoles } from 'driverama-core/auth/openid/useRoles'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { toast } from 'driverama-core/components/toast/Toast'
import { size } from 'driverama-core/styles/spacing'
import equal from 'fast-deep-equal'
import { Dispatch, ReactNode, SetStateAction } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SEditButton } from './AuctionDetailOtherForm.styled'
import {
  FormValues,
  useWinnerChangeValidationSchema
} from './AuctionDetailOtherForm.utils'

type Props = {
  defaultValues: Partial<FormValues>
  children: ReactNode
  auctionId?: string
  refetchAuctionValues: () => Promise<void>
  disabledEdit: boolean
  setDisabledEdit: Dispatch<SetStateAction<boolean>>
  opportunityState?: OpportunityState | null
}

export function AuctionDetailOtherForm({
  defaultValues,
  children,
  auctionId,
  refetchAuctionValues,
  disabledEdit,
  setDisabledEdit,
  opportunityState
}: Props) {
  const { t } = useTranslation(['auction'])

  const { mutateAsync } = useAuctionWinnerMutation(auctionId)
  const hasRole = useHasRoles(['AUCTIONS:AUCTION:CHANGE_WINNER'])

  const canEditBid =
    (opportunityState === 'DEAL' || opportunityState === 'APPROVED') &&
    hasRole['AUCTIONS:AUCTION:CHANGE_WINNER']

  const { schema, errorMap } = useWinnerChangeValidationSchema()

  const form = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(schema, { errorMap }),
    mode: 'all'
  })

  const isSubmitting = form.formState.isSubmitting

  const onSubmit = async (data: FormValues) => {
    try {
      if (equal(data, defaultValues)) {
        setDisabledEdit(true)
        return
      }

      await mutateAsync(data)

      await refetchAuctionValues()

      setDisabledEdit(true)

      toast({
        type: 'success',
        content: t('auction:winner_change_success')
      })
    } catch (e) {
      toast({
        error: null,
        content: t('auction:winner_change_error', { error: e }),
        type: 'error'
      })
    }
  }

  const handleEditBtnClick = () => {
    if (disabledEdit) {
      setDisabledEdit(false)

      return
    }

    form.handleSubmit(onSubmit)()
  }

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        css={{ position: 'relative' }}
      >
        {canEditBid && (
          <SEditButton
            variant="secondary"
            onClick={handleEditBtnClick}
            disabled={isSubmitting}
            cornerRadius="small"
          >
            {disabledEdit ? t('auction:edit_button') : t('auction:save_button')}

            {isSubmitting && (
              <SpinnerCentered
                variant="x-small"
                css={{ marginLeft: size(4) }}
              />
            )}
          </SEditButton>
        )}

        {children}
      </form>
    </FormProvider>
  )
}
