import type { CookieSerializeOptions } from 'cookie'

export interface CookieType {
  key: string
  options: CookieSerializeOptions
}

type CookieAuthType = 'profile'

export function defaultAuthCookies(): Record<CookieAuthType, CookieType> {
  const prefix = ''

  return {
    profile: {
      key: `${prefix}profile`,
      options: {
        httpOnly: false,
        sameSite: 'lax',
        path: '/',
        secure: false
      }
    }
  }
}
