import { AuctionSearchResponseAggItem } from 'api/driverama/auctions/searchAuctionsAgg'
import { Flex } from 'driverama-core/components/Flex'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuctionListMode } from '../Auctions.utils'
import { AuctionsDetail } from './AuctionsDetail'
import { AuctionState } from './AuctionsDetail.utils'
import { SChip, SChipWrapper } from './AuctionsDetailWrapper.styled'
import { ExportButton } from './export/ExportButton'
import { AuctionsDetailNotes } from './notes/AuctionsDetailNotes'

interface Props {
  carId: string | undefined
  isInHistory?: boolean
  auctionId?: string
  mode?: AuctionListMode
  auctionHasBid?: boolean
  plannedEndAt?: string | null
  auctionState?: AuctionState
  winningBid?: AuctionSearchResponseAggItem['winningBid']
}

export function AuctionsDetailWrapper(props: Props) {
  const { t } = useTranslation(['core', 'auction'])
  const [section, setSection] = useState<'detail' | 'note'>('detail')

  return (
    <>
      <SChipWrapper auctionHasBid={!!props.auctionHasBid}>
        <Flex variant="row" gap={2} justify={['start', 'start', 'center']}>
          <SChip
            selected={section === 'detail'}
            onClick={() => setSection('detail')}
          >
            {t('auction:detail_car_detail')}
          </SChip>
          <SChip
            selected={section === 'note'}
            onClick={() => setSection('note')}
          >
            {t('auction:detail_notes')}
          </SChip>
        </Flex>

        {props.carId && <ExportButton carId={props.carId} />}
      </SChipWrapper>

      {section === 'detail' ? (
        <AuctionsDetail {...props} />
      ) : (
        <AuctionsDetailNotes auctionId={props.auctionId} />
      )}
    </>
  )
}
