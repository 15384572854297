import { TypedAuctionDiff } from 'api/driverama/auctions/diff'
import {
  LovFeature,
  useLovCarFeaturesSearchQuery
} from 'api/driverama/lov/lovFeatures'
import { isNotNil } from 'driverama-core/utils/types'
import { useMemo } from 'react'

export type LovFeatureWithDiff = LovFeature & {
  changeType?: 'ARRAY_ELEMENT_ADDED' | 'ARRAY_ELEMENT_REMOVED'
}

export function useFeatureDiffs(
  features: LovFeature[],
  diffs: TypedAuctionDiff<'$.car.features'>[],
  carId?: string,
  auctionId?: string
) {
  // LOV stuff
  const defaultOpts = {
    enabled: !!auctionId && !!carId,
    refetchOnWindowFocus: false
  }

  const featuresQuery = useLovCarFeaturesSearchQuery(
    {
      filter: {
        ids: [],
        categoryIdIncludeNull: true
      }
    },
    defaultOpts
  )

  const mappedFeatureDiffs: LovFeatureWithDiff[] = useMemo(() => {
    if (!!diffs && !!featuresQuery.data) {
      return diffs
        .map(item => {
          if (item.changeType === 'ARRAY_ELEMENT_ADDED') {
            const newValue = featuresQuery.data.content?.find(
              feature => feature.id === item.newValue
            )

            if (newValue) {
              return {
                ...newValue,
                changeType: item.changeType
              }
            }
          }

          if (item.changeType === 'ARRAY_ELEMENT_REMOVED') {
            const oldValue = featuresQuery.data.content?.find(
              feature => feature.id === item.oldValue
            )

            if (oldValue) {
              return {
                ...oldValue,
                changeType: item.changeType
              }
            }
          }

          return undefined
        })
        .filter(isNotNil)
    }

    return []
  }, [diffs, featuresQuery.data])

  return useMemo(() => {
    const featuresUnchanged = features.filter(
      feature =>
        !mappedFeatureDiffs.find(diffFeature => diffFeature.id === feature.id)
    )

    return [...mappedFeatureDiffs, ...featuresUnchanged]
  }, [mappedFeatureDiffs, features])
}
