import { AuctionDiff } from 'api/driverama/auctions/diff'
import { unique } from 'driverama-core/utils/array'
import { isNotNil } from 'driverama-core/utils/types'

const CAR_COST_ITEM_ID_REGEX = /\[(.*?)\]/

export type CostItemDiff = {
  id: string
  cost: number
  type?: string
  standardRepairId?: string
  name?: string
  description?: string
  isItemDiff: boolean
  changeType: Extract<
    AuctionDiff['changeType'],
    'VALUE_ADDED' | 'VALUE_REMOVED' | 'VALUE_CHANGED'
  >
}

export type CostSectionDiff = {
  id: string
  changeType?: Extract<
    AuctionDiff['changeType'],
    'VALUE_ADDED' | 'VALUE_REMOVED' | 'VALUE_CHANGED'
  >
  cost: number
}

export function isCostItemDiff(
  x: CostItemDiff | CostSectionDiff
): x is CostItemDiff {
  return (x as CostItemDiff).isItemDiff === true
}

export type CostDiffs = {
  costItemDiffs: CostItemDiff[]
  costSectionDiffs: CostSectionDiff[]
}

function costSectionIdMapper(section: string) {
  if (section.includes('exteriorTechnicalConditionCategory')) {
    return 'EXTERIOR'
  }

  if (section.includes('interiorTechnicalConditionCategory')) {
    return 'INTERIOR'
  }

  if (section.includes('mechanicsTechnicalConditionCategory')) {
    return 'MECHANICS'
  }

  return section
}

/**
 * Function that takes car auction diffs and return formatted "Cost diffs"
 */
export function processCostsDiffs(diffs: AuctionDiff[]): CostDiffs {
  const costItemDiffIds = unique(
    diffs
      .map(diff => diff.propertyName.match(CAR_COST_ITEM_ID_REGEX)?.[1])
      .filter(isNotNil)
  )

  const costSectionDiffs = diffs
    .filter(diff => diff.propertyName.includes('sums.totalRepairCosts'))
    .map(sectionDiff => {
      const sectionId = costSectionIdMapper(sectionDiff.propertyName)

      if (sectionDiff.changeType === 'VALUE_ADDED') {
        return {
          id: sectionId,
          cost: Number(sectionDiff.newValue ?? '0'),
          changeType: sectionDiff.changeType
        }
      }

      if (
        sectionDiff.changeType === 'VALUE_CHANGED' ||
        sectionDiff.changeType === 'VALUE_REMOVED'
      ) {
        return {
          id: sectionId,
          cost: Number(sectionDiff.oldValue ?? '0'),
          changeType: sectionDiff.changeType
        }
      }

      return undefined
    })
    .filter(isNotNil)

  const costItemDiffs = costItemDiffIds.map(id =>
    diffs
      .filter(diff => diff.propertyName.includes(id))
      .reduce((acc, curr) => {
        const propertyNameParts = curr.propertyName.split('.')
        const attribute = propertyNameParts[propertyNameParts.length - 1]
        const value =
          attribute === 'cost'
            ? Number(curr.oldValue ?? '0')
            : curr.changeType === 'VALUE_ADDED'
            ? curr.newValue
            : curr.oldValue

        return {
          ...acc,
          id,
          isItemDiff: true,
          changeType: curr.changeType as
            | 'VALUE_ADDED'
            | 'VALUE_REMOVED'
            | 'VALUE_CHANGED',
          [attribute]: value
        }
      }, {} as CostItemDiff)
  )
  return { costItemDiffs, costSectionDiffs }
}
