import { useCarCostsQuery } from 'api/driverama/cars/carCosts'
import { useCarTechnicalSectionsQuery } from 'api/driverama/cars/carTechnicalSections'
import { useLovTechnicalSectionsQuery } from 'api/driverama/lov/lovTechnicalSections'
import { isNotNil } from 'driverama-core/utils/types'
import { useMemo } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { useLovStandardRepairsSearchQuery } from '../../../../api/driverama/lov/lovStandardRepairs'
import {
  CostDiffs,
  CostItemDiff,
  CostSectionDiff
} from '../diff/repairCosts/RepairCosts.utils'

function getLovCosts(t: TFunction<'auction'>) {
  return [
    { id: 'TIRES', name: t('detail_repair_costs_tires') },
    { id: 'KEYS', name: t('detail_repair_costs_keys') },
    { id: 'OTHER', name: t('detail_repair_costs_others') },
    { id: 'STANDARD_REPAIR', name: t('detail_repair_costs_standard') }
  ]
}

export type RepairCostSectionItem = {
  sectionId: string
  name: string
  cost: number
  diff?: CostItemDiff | CostSectionDiff
}

export function useCostsSections(carId: string, diffs: CostDiffs) {
  const { t } = useTranslation('auction')
  const opts = { enabled: !!carId, refetchOnWindowFocus: false }

  const sections = useCarTechnicalSectionsQuery({ carId }, opts)
  const lovSections = useLovTechnicalSectionsQuery(undefined, opts)
  const costsSections = useCarCostsQuery(carId, opts)
  const lovStandardRepairs = useLovStandardRepairsSearchQuery({
    ids: []
  })

  const queries = [sections, lovSections, costsSections, lovStandardRepairs]

  const lovCosts = getLovCosts(t)

  const data = useMemo(() => {
    if (!!sections.data && !!lovSections.data && !!costsSections.data) {
      const sectionsGroup = sections.data.sections
        .sort((a, b) => a.sectionId.localeCompare(b.sectionId))
        .map(section => ({
          id: section.id,
          sectionId: section.sectionId,
          name:
            lovSections.data?.content.find(
              lovSection => lovSection.id === section.sectionId
            )?.name || '',
          cost: section.repairCosts || 0,
          diff: diffs.costSectionDiffs.find(
            diff => diff.id === section.sectionId
          )
        }))

      const costsGroup = lovCosts.reduce(
        (acc: RepairCostSectionItem[], item) => {
          const matchedItems = costsSections.data?.items
            .filter(apiItem => apiItem.type === item.id)
            .map(apiItem => ({ ...apiItem, name: item.name }))

          if (matchedItems && !!matchedItems.length) {
            matchedItems.forEach(matchedItem => {
              const description =
                item.id === 'STANDARD_REPAIR'
                  ? lovStandardRepairs.data?.content.find(
                      standardRepair =>
                        standardRepair.id === matchedItem.standardRepairId
                    )?.name
                  : matchedItem.description

              // find cost item diff
              const costItemDiff = diffs.costItemDiffs.find(
                diff => diff.id === matchedItem.id
              )

              const isOtherTypeItem = item.id === 'OTHER'

              // if diff for cost item with description difference was found and type of item is 'OTHER' then add name prop
              if (!!costItemDiff?.description && isOtherTypeItem) {
                costItemDiff.name = [
                  matchedItem.name,
                  costItemDiff.description
                ].join(' - ')
              }

              acc.push({
                sectionId: `${item.id}-${description}`,
                name: [matchedItem.name, description]
                  .filter(isNotNil)
                  .join(' - '),
                cost: matchedItem.cost || 0,
                diff: costItemDiff
              })
            })

            return acc
          }

          const totalCost = matchedItems?.reduce(
            (previousValue, currentValue) =>
              previousValue + (currentValue.cost || 0),
            0
          )

          if (totalCost !== 0) {
            acc.push({
              sectionId: item.id,
              name: item.name,
              cost: totalCost || 0
            })
          }

          return acc
        },
        []
      )

      const removedSections = diffs.costSectionDiffs
        .filter(diff => diff.changeType === 'VALUE_REMOVED')
        .map(diff => ({
          sectionId: diff.id,
          cost: diff.cost,
          name: diff.id,
          diff
        }))

      const removedCostItems = diffs.costItemDiffs
        .filter(diff => diff.changeType === 'VALUE_REMOVED')
        .map(diff => {
          const name = lovCosts.find(lovCost => lovCost.id === diff.type)?.name

          const standardRepairDescription = lovStandardRepairs.data?.content.find(
            standardRepair => standardRepair.id === diff.standardRepairId
          )?.name
          const otherTypeRepairDescription = diff.description

          const description =
            diff.type === 'OTHER'
              ? otherTypeRepairDescription
              : standardRepairDescription

          return {
            sectionId: `diff_${diff.type}-${description}`,
            cost: 0,
            name: [name, description].filter(isNotNil).join(' - '),
            diff
          }
        })

      const items = [
        ...sectionsGroup,
        ...removedSections,
        ...costsGroup,
        ...removedCostItems
      ]

      return {
        items,
        total: items.reduce(
          (previousValue, currentValue) => previousValue + currentValue.cost,
          0
        ),
        diffTotal: items.reduce((acc, curr) => {
          // if there is no diff, return original value
          if (!curr.diff) {
            return acc + curr.cost
          }

          // if there is a diff, return previous value so we can compare total values
          return acc + (curr.diff?.cost ? Number(curr.diff?.cost) : 0)
        }, 0)
      }
    }

    return undefined
  }, [
    diffs,
    sections.data,
    lovSections.data,
    costsSections.data,
    lovCosts,
    lovStandardRepairs.data
  ])

  return {
    data,
    isLoading: queries.find(query => query.isLoading),
    isError: queries.find(query => query.isError)
  }
}
