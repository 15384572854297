import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextHeader, TextSubhead } from 'driverama-core/components/text/Text'
import { useCopyToClipboard } from 'driverama-core/utils/hooks'
import IconCopy from 'images/icons/IconCopy.svg'
import { useTranslation } from 'react-i18next'
import { TypedAuctionDiff } from '../../../../api/driverama/auctions/diff'
import { StrikeTextBody } from '../../../../components/diff/StrikeTextBody'
import {
  SButton,
  SCard,
  SCards,
  SLabel
} from './AuctionsDetailIdentification.styled'

interface Props {
  license?: string
  vin?: string
  diffs?: TypedAuctionDiff<'$.car.licensePlate' | '$.car.vin'>[]
}

export function AuctionsDetailIdentification({ license, vin, diffs }: Props) {
  const { t } = useTranslation(['auction'])
  const { copyToClipboard } = useCopyToClipboard()

  const copy = (text: string) =>
    copyToClipboard(text, t('auction:detail_identification_copy_success'))

  const licenseDiff = diffs?.find(
    diff => diff.propertyName === '$.car.licensePlate'
  )
  const vinDiff = diffs?.find(diff => diff.propertyName === '$.car.vin')

  return (
    <section>
      <TextSubhead>{t('auction:detail_identification')}</TextSubhead>
      <Spacer axis="vertical" size={4} />
      <SCards>
        {license && (
          <SCard>
            <Flex variant="row" justify="between">
              <Flex variant="row" gap={2}>
                <TextHeader variant="h6" as="strong">
                  {license}
                </TextHeader>
                {licenseDiff && licenseDiff.changeType === 'VALUE_CHANGED' && (
                  <StrikeTextBody>{licenseDiff.oldValue}</StrikeTextBody>
                )}
              </Flex>
              <SButton onClick={() => copy(license)}>
                <IconCopy />
              </SButton>
            </Flex>
            <SLabel>{t('auction:detail_identification_license')}</SLabel>
          </SCard>
        )}
        {vin && (
          <SCard>
            <Flex variant="row" justify="between">
              <Flex variant="row" gap={2}>
                <TextHeader variant="h6" as="strong">
                  {vin}
                </TextHeader>
                {vinDiff && vinDiff.changeType === 'VALUE_CHANGED' && (
                  <StrikeTextBody>{vinDiff.oldValue}</StrikeTextBody>
                )}
              </Flex>
              <SButton onClick={() => copy(vin)}>
                <IconCopy />
              </SButton>
            </Flex>
            <SLabel>{t('auction:detail_identification_vin')}</SLabel>
          </SCard>
        )}
      </SCards>
    </section>
  )
}
