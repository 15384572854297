import { css, SerializedStyles } from '@emotion/react'
import { Portal } from 'driverama-core/components/portal/Portal'
import IconCross from 'driverama-core/images/icons/IconCross.svg'
import { size } from 'driverama-core/styles/spacing'
import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useRef,
  useState
} from 'react'
import ReactFocusLock from 'react-focus-lock'
import { RemoveScroll } from 'react-remove-scroll'
import { useKey } from 'react-use'
import { SBackground, SCloseButton, SContent } from './Modal.styled'
import { ModalAllowCloseContext, ModalCloseContext } from './Modal.utils'

type Props = {
  isOpen: boolean
  onClose: () => void
  className?: string
  backgroundStyles?: SerializedStyles
  closeLabel: string
  hideCross?: boolean
  onCloseWithCross?: (() => void) | null
}

export function Modal(props: PropsWithChildren<Props>) {
  const {
    onClose,
    isOpen,
    children,
    className,
    backgroundStyles,
    closeLabel,
    onCloseWithCross,
    hideCross
  } = props
  const [allowClose, setAllowClose] = useState(true)

  const handleClose = () => {
    if (allowClose) {
      onClose()
    }
  }

  useKey(
    'Escape',
    event => {
      event.stopPropagation()

      if (allowClose) {
        if (onCloseWithCross !== null && onCloseWithCross) {
          onCloseWithCross()
        } else {
          handleClose()
        }
      }
    },
    undefined,
    [allowClose]
  )

  return (
    <Portal>
      {isOpen ? (
        <ModalAllowCloseContext.Provider value={{ allowClose, setAllowClose }}>
          <ModalCloseContext.Provider value={handleClose}>
            <ReactFocusLock returnFocus autoFocus={false}>
              <SBackground
                onClick={
                  onCloseWithCross !== null ? onCloseWithCross : handleClose
                }
                css={backgroundStyles}
              />
              <RemoveScroll forwardProps enabled>
                {/* TODO: add animations */}
                <SContent role="dialog" aria-modal="true" className={className}>
                  {children}

                  {hideCross !== true && (
                    <SCloseButton
                      onClick={
                        onCloseWithCross ? onCloseWithCross : handleClose
                      }
                      aria-label={closeLabel}
                    >
                      <IconCross
                        css={css({ display: 'block' })}
                        width={size(6)}
                        height={size(6)}
                      />
                    </SCloseButton>
                  )}
                </SContent>
              </RemoveScroll>
            </ReactFocusLock>
          </ModalCloseContext.Provider>
        </ModalAllowCloseContext.Provider>
      ) : null}
    </Portal>
  )
}

type UseModalOpts = {
  wrapperStyles?: SerializedStyles
  backgroundStyles?: SerializedStyles
  wide?: boolean
  closeLabel: string
  hideCross?: boolean
  onCloseWithCross?: (() => void) | null
}

export const useModal = (render: () => ReactNode, opts: UseModalOpts) => {
  const { wrapperStyles, backgroundStyles } = opts || {}

  const [isOpen, setIsOpen] = useState(false)
  const closeCallback = useRef<(() => void) | null>(null)

  const openModal: () => Promise<void> = useCallback(() => {
    return new Promise(resolve => {
      setIsOpen(true)
      closeCallback.current = resolve
    })
  }, [])

  const onCloseModal = () => {
    closeCallback.current && closeCallback.current()
    setIsOpen(false)
  }

  const modal = (
    <Modal
      backgroundStyles={backgroundStyles}
      css={wrapperStyles}
      isOpen={isOpen}
      onClose={onCloseModal}
      closeLabel={opts.closeLabel}
      hideCross={opts.hideCross}
      onCloseWithCross={opts.onCloseWithCross}
    >
      {isOpen ? render() : null}
    </Modal>
  )

  return [modal, openModal] as const
}
