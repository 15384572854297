import styled from '@emotion/styled'
import { size, spaceX } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'

export const SContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: ${size(3)};
`

export const SStars = styled.div`
  display: flex;

  ${spaceX(2)};
`

export const SStar = styled.div<{
  isHighlighted: boolean
  isDiff?: boolean
}>`
  color: ${({ isHighlighted }) =>
    color(isHighlighted ? 'sun' : 'almond-l-100')};

  opacity: ${({ isDiff }) => (isDiff ? 0.4 : 1)};
`
