import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { TextBody, TextSubhead } from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import { useCostsSections } from 'sections/auctions/detail/repairCosts/AuctionsDetailRepairCosts.utils'
import { AuctionDiff } from '../../../../api/driverama/auctions/diff'
import { RepairCostItem } from '../diff/repairCosts/RepairCostItem'
import { RepairCostTotal } from '../diff/repairCosts/RepairCostTotal'
import { processCostsDiffs } from '../diff/repairCosts/RepairCosts.utils'
import { SGrid, SItem } from './AuctionsDetailRepairCosts.styled'

interface Props {
  carId: string
  diffs?: AuctionDiff[]
}

export function AuctionsDetailRepairCosts({ carId, diffs }: Props) {
  const { t } = useTranslation(['auction', 'core'])

  const costDiffs = processCostsDiffs(diffs ?? [])
  const { data, isError, isLoading } = useCostsSections(carId, costDiffs)

  return (
    <section>
      <TextSubhead>{t('auction:detail_repair_costs')}</TextSubhead>
      <Spacer axis="vertical" size={4} />
      {isLoading ? (
        <SpinnerCentered />
      ) : isError ? (
        <TextBody>{t('core:error_api')}</TextBody>
      ) : (
        <>
          <SGrid>
            {data?.items.map(section => (
              <SItem key={section.sectionId}>
                <RepairCostItem {...section} />
              </SItem>
            ))}
          </SGrid>
          <SItem>
            <RepairCostTotal
              total={data?.total ?? 0}
              diffTotal={data?.diffTotal ?? 0}
            />
          </SItem>
        </>
      )}
    </section>
  )
}
