import { operations } from 'api/driverama/generated/lov'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { Maybe } from 'driverama-core/utils/types'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'

export type LovExtendedModelsSearchResponse = operations['getModels_1']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryParams = operations['getModels_1']['parameters']['query']
type QueryBody = operations['getModels_1']['requestBody']['content']['application/json']

type QueryOpts = UseQueryOptions<
  unknown,
  unknown,
  LovExtendedModelsSearchResponse
>

export function useLovExtendedModelsSearchQuery(
  body: QueryBody,
  searchParams?: QueryParams,
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.lovExtendedModelsSearch(body),
    async () => {
      const res = await apiFetcher<LovExtendedModelsSearchResponse>(
        URLS.lovExtendedModelsSearch,
        { method: HTTPMethod.POST, body, searchParams }
      )

      return res.json
    },
    opts
  )
}

type ExtendedModelListArgs = {
  makeId: Maybe<string>
  modelId: Maybe<string>
  year?: Maybe<number>
}

export function useExtendedModelList(args: ExtendedModelListArgs) {
  const { makeId, modelId, year } = args

  const { data, ...rest } = useLovExtendedModelsSearchQuery(
    {
      filter: {
        ids: [],
        makeIds: makeId ? [makeId] : [],
        modelIds: modelId ? [modelId] : [],
        yearFromTo: year ?? undefined,
        yearFromIncludeNull: true,
        yearToFrom: year ?? undefined,
        yearToIncludeNull: true,
        active: true
      }
    },
    {},
    { enabled: !!makeId && !!modelId }
  )

  const extendedModels = useMemo(() => {
    return (
      data?.content?.map(modeLine => ({
        label: modeLine.name || modeLine.id,
        value: modeLine.id
      })) || []
    )
  }, [data])

  return { ...rest, extendedModels }
}
