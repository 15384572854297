import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { paths } from 'driverama-core/api/driverama/generated/cars'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'

export type CarTechnicalSubCategoriesResponse =
  | paths['/admin/cars/technical-condition-subcategories/search-agg']['post']['responses']['200']['content']['application/com.driverama-v2+json']
  | undefined
type CarTechnicalSubCategoriesBody = paths['/admin/cars/technical-condition-subcategories/search-agg']['post']['requestBody']['content']['application/json']

type QueryOpts = UseQueryOptions<
  unknown,
  unknown,
  CarTechnicalSubCategoriesResponse
>

export function useCarTechnicalSubCategories(
  body?: CarTechnicalSubCategoriesBody,
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.carTechnicalSubCategories(body),
    async () => {
      const res = await apiAuthFetcher<CarTechnicalSubCategoriesResponse>(
        URLS.carTechnicalSubCategories,
        {
          method: HTTPMethod.POST,
          headers: {
            Accept: 'application/com.driverama-v2+json'
          },
          body
        }
      )
      return res.json
    },
    opts
  )
}
