import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { paths } from 'driverama-core/api/driverama/generated/cars'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'

type CarTechnicalSectionsResponse =
  | paths['/admin/cars/technical-condition-sections/search-agg']['post']['responses']['200']['content']['application/com.driverama-v2+json']
  | undefined
type CarTechnicalSectionsBody = paths['/admin/cars/technical-condition-sections/search-agg']['post']['requestBody']['content']['application/json']

type QueryOpts = UseQueryOptions<unknown, unknown, CarTechnicalSectionsResponse>

export function useCarTechnicalSectionsQuery(
  body?: CarTechnicalSectionsBody,
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.carTechnicalSections(body),
    async () => {
      const res = await apiAuthFetcher<CarTechnicalSectionsResponse>(
        URLS.carTechnicalSections,
        {
          method: HTTPMethod.POST,
          headers: {
            Accept: 'application/com.driverama-v2+json'
          },
          body
        }
      )
      return res.json
    },
    opts
  )
}
