import { css } from '@emotion/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAuctionDetailQuery } from 'api/driverama/auctions/getAuctions'
import { useEmployeesSearchQuery } from 'api/driverama/employees/search'
import { useNoteCreateMutation } from 'api/driverama/notes/create'
import { useNotesSearchQuery } from 'api/driverama/notes/search'
import { Button } from 'driverama-core/components/button/Button'
import { Flex } from 'driverama-core/components/Flex'
import { FormControl } from 'driverama-core/components/formControl/FormControl'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextSubhead } from 'driverama-core/components/text/Text'
import { toast } from 'driverama-core/components/toast/Toast'
import { size } from 'driverama-core/styles/spacing'
import { isNotNil, isString } from 'driverama-core/utils/types'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  usePartnerMapper,
  usePartnersList
} from '../../../../api/driverama/partners/search'
import { Note } from '../../../../components/note/Note'
import { AuctionsDetailError } from '../error/AuctionsDetailError'
import { SContainer, SSpinner } from './AuctionsDetailNotes.styled'
import {
  AuctionsDetailNoteFormValues,
  useAuctionsDetailNoteFormValidationSchema
} from './AuctionsDetailNotes.utils'

interface Props {
  auctionId?: string
}

export function AuctionsDetailNotes(props: Props) {
  const { t } = useTranslation(['core', 'auction'])

  const { errorMap, schema } = useAuctionsDetailNoteFormValidationSchema()
  const partnerMapper = usePartnerMapper()
  const form = useForm<AuctionsDetailNoteFormValues>({
    mode: 'all',
    resolver: zodResolver(schema, { errorMap })
  })

  const auctionDetailQuery = useAuctionDetailQuery(props.auctionId, {
    enabled: !!props.auctionId
  })

  const notesSearchQuery = useNotesSearchQuery(
    {
      filter: {
        opportunityIds: [auctionDetailQuery.data?.opportunityId].filter(
          isNotNil
        ),
        employeeIds: []
      }
    },
    {
      enabled: !!auctionDetailQuery.data?.opportunityId
    }
  )

  const {
    mutateAsync,
    isLoading: noteCreationIsLoading
  } = useNoteCreateMutation(auctionDetailQuery.data?.opportunityId ?? '')

  const notesUsers = notesSearchQuery.data?.content
    .map(note => note.employeeId)
    .filter(isNotNil)

  const employeesSearchQuery = useEmployeesSearchQuery(
    {
      ids: notesUsers ?? []
    },
    {
      enabled: !!notesUsers?.length
    }
  )

  const partnersList = usePartnersList()

  const queries = [
    auctionDetailQuery,
    notesSearchQuery,
    employeesSearchQuery,
    partnersList
  ]
  const isLoading = queries.some(q => q.isLoading)
  const isError = queries.some(q => q.isError)

  const possibleNotesUsers = [
    ...(employeesSearchQuery.data?.content.map(employee => ({
      id: employee.id,
      name: [employee.firstName, employee.surname].filter(isNotNil).join(' ')
    })) ?? []),
    ...(partnersList.data?.content.map(partner => ({
      id: partner.erpId,
      name: partnerMapper(partner.erpId)
    })) ?? [])
  ]

  async function handleSubmit(data: AuctionsDetailNoteFormValues) {
    try {
      const { text } = data

      if (isString(text) && text.trim()) {
        const note = await mutateAsync({
          text,
          noteSource: 'AUCTION_APP'
        })

        if (!note) {
          toast({
            type: 'error',
            content: t('core:error_common'),
            error: null
          })
          return
        }

        form.reset()
        await notesSearchQuery.refetch()

        toast({
          content: t('auction:detail_notes_created'),
          type: 'success'
        })
      }
    } catch (error) {
      toast({
        type: 'error',
        content: t('core:error_common'),
        error
      })
    }
  }

  function renderContent() {
    if (isLoading) {
      return <SSpinner />
    }

    if (isError) {
      return <AuctionsDetailError />
    }

    return (
      <SContainer>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <TextSubhead color="night-l-100">
              {t('auction:detail_notes_write_new')}
            </TextSubhead>
            <Spacer size={4} />
            <Flex variant={['column', 'row']} gap={4}>
              <FormControl
                label={t('auction:detail_notes_your_note')}
                css={css`
                  width: 100%;
                `}
              >
                <input
                  disabled={noteCreationIsLoading}
                  type="text"
                  {...form.register('text')}
                />
              </FormControl>

              <Button
                disabled={noteCreationIsLoading}
                variant="primary"
                type="submit"
                css={{
                  minWidth: size(42),
                  height: size(14)
                }}
              >
                {t('auction:detail_notes_send')}
              </Button>
            </Flex>
          </form>
        </FormProvider>
        {!!notesSearchQuery.data?.content.length && (
          <>
            <Spacer size={14} />
            <TextSubhead color="night-l-100">
              {t('auction:detail_notes_title')}
            </TextSubhead>
            <Spacer size={5} />
            <Flex variant="column" gap={6}>
              {notesSearchQuery.data?.content
                .sort(
                  (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt)
                )
                .map(note => {
                  const createdBy = possibleNotesUsers.find(
                    user => user.id === note.employeeId
                  )

                  return (
                    <Note
                      createdAt={note.createdAt}
                      createdBy={createdBy?.name}
                    >
                      {note.text}
                    </Note>
                  )
                })}
            </Flex>
          </>
        )}
      </SContainer>
    )
  }

  return renderContent()
}
