import styled from '@emotion/styled'
import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'
import { TextBody } from 'driverama-core/components/text/Text'

export const SGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${size(54)}, 1fr));
  grid-column-gap: ${size(10)};
  grid-row-gap: ${size(3)};
`

export const SItem = styled.li`
  display: flex;
  align-items: center;

  color: ${color('night-l-100')};

  svg {
    min-width: ${size(5)};
    min-height: ${size(5)};

    margin-right: ${size(1)};
  }
`

export const SMore = styled(SItem)`
  ${TextBody} {
    color: inherit;
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }
`
