import { size } from 'driverama-core/styles/spacing'
import { color, radius, zIndex } from 'driverama-core/styles/variables'
import { media } from 'driverama-core/styles/media'
import { css } from '@emotion/react'

export const imageGalleryModalStyles = css`
  width: 100%;
  height: 100%;
  max-width: calc(100vw - ${size(58)});
  padding: 0;
  overflow: unset;
  background-color: transparent;
  box-shadow: none;
  max-height: 100%;

  & button {
    top: ${size(6)};
    right: ${size(-21)};
    border-radius: ${radius('full')};
    width: ${size(12)};
    height: ${size(12)};
    border: 1px solid ${color('white', 0.5)};
    color: ${color('white')};

    @media ${media.lte('LG')} {
      top: ${size(-16)};
      right: 0;
    }

    @media ${media.lte('MD')} {
      right: ${size(4)};
    }

    @media ${media.lte('tablet')} {
      top: ${size(6)};
      z-index: ${zIndex('modal')};
    }

    & svg {
      height: ${size(6)};
      width: ${size(6)};
    }
  }

  @media ${media.lte('XL')} {
    width: 75%;
  }
  @media ${media.lte('LG')} {
    width: 95%;
  }
  @media ${media.lte('MD')} {
    width: 100%;
    max-width: 100%;
    left: 0;
  }
`

export const imageFullGalleryModalStyles = css`
  width: 100%;
  height: 100%;
  max-width: calc(100vw - ${size(58)});
  padding: 0;
  overflow: unset;
  background-color: transparent;
  box-shadow: none;
  max-height: 100%;
  z-index: ${zIndex('top')};

  @media ${media.lte('tablet')} {
    max-width: 100%;
  }

  & button:not(.custom) {
    top: ${size(6)};
    right: ${size(-21)};
    border-radius: ${radius('full')};
    width: ${size(12)};
    height: ${size(12)};
    border: 1px solid ${color('white', 0.5)};
    color: ${color('white')};
    z-index: ${zIndex('top')};

    @media ${media.lte('LG')} {
      right: 0;
    }

    @media ${media.lte('MD')} {
      right: ${size(4)};
    }

    @media ${media.lte('tablet')} {
      top: ${size(4)};
    }

    & svg {
      height: ${size(6)};
      width: ${size(6)};
    }
  }
  @media ${media.lte('XL')} {
    width: 75%;
  }
  @media ${media.lte('LG')} {
    width: 95%;
  }
  @media ${media.lte('MD')} {
    width: 100%;
    left: 0;
  }
`

export const imageGalleryModalBackgroundStyles = css`
  background-color: ${color('black', 0.9)};
`

export const imageFullGalleryModalBackgroundStyles = css`
  background-color: ${color('black', 0.9)};
  z-index: ${zIndex('top')};
`
