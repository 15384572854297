import { useValidationMessage } from 'driverama-core/utils/validations'
import { ReactNode } from 'react'
import { Controller } from 'react-hook-form'
import { SelectUncontrolled } from './SelectUncontrolled'

export type SelectProps = {
  label?: string
  emptyLabel?: string
  name: string
  options: { value: string; label: string }[]
  isLoading?: boolean
  isRequired?: boolean
  onFocus?: () => void
  onBlur?: () => void
  onChange?: (newValue: string) => void
  error?: ReactNode
  disabled?: boolean
  readOnly?: boolean
  extraOptions?: { value: string; label: string }[]
  variant?: 'rounded' | 'normal'
  disabledMode?: 'solid'
  hint?: ReactNode
  className?: string
}

export function Select(props: SelectProps) {
  const validationError = useValidationMessage(props.name)

  return (
    <Controller
      name={props.name}
      render={({ field: { onChange, value } }) => (
        <SelectUncontrolled
          {...props}
          error={props.error ?? validationError}
          onInnerChange={onChange}
          value={value}
        />
      )}
    />
  )
}
