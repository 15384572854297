import { StrikeTextBody } from 'components/diff/StrikeTextBody'
import { StrikeTextBodyBold } from 'components/diff/StrikeTextBodyBold'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { Tag } from 'driverama-core/components/tag/Tag'
import { TextBody, TextBodyBold } from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import { RepairCostSectionItem } from '../../repairCosts/AuctionsDetailRepairCosts.utils'
import { isCostItemDiff } from './RepairCosts.utils'

export function RepairCostItem({ name, cost, diff }: RepairCostSectionItem) {
  const { t } = useTranslation(['auction'])

  if (diff?.changeType === 'VALUE_ADDED') {
    return (
      <>
        <Flex variant="row">
          <TextBody>{name}</TextBody>
          <Spacer size={2} axis="horizontal" />
          <Tag variant="dark">{t('auction:diff_value_added_label')}</Tag>
        </Flex>

        <TextBodyBold>{t('auction:price', { price: cost })}</TextBodyBold>
      </>
    )
  }

  if (diff?.changeType === 'VALUE_CHANGED') {
    const hasNameChanged = isCostItemDiff(diff) && !!diff.name

    return (
      <>
        <Flex variant="column">
          {hasNameChanged && <StrikeTextBody>{diff.name}</StrikeTextBody>}

          <TextBody>{name}</TextBody>
        </Flex>

        <Flex variant={hasNameChanged ? 'column' : 'row'}>
          <StrikeTextBodyBold>
            {t('auction:price', { price: diff.cost })}
          </StrikeTextBodyBold>
          <Spacer size={2} axis="horizontal" />
          <TextBodyBold>{t('auction:price', { price: cost })}</TextBodyBold>
        </Flex>
      </>
    )
  }

  if (diff?.changeType === 'VALUE_REMOVED') {
    return (
      <>
        <StrikeTextBody>{name}</StrikeTextBody>
        <StrikeTextBodyBold>
          {t('auction:price', { price: diff.cost })}
        </StrikeTextBodyBold>
      </>
    )
  }

  return (
    <>
      <TextBody>{name}</TextBody>
      <TextBodyBold>{t('auction:price', { price: cost })}</TextBodyBold>
    </>
  )
}
