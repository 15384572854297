import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { paths } from 'driverama-core/api/driverama/generated/cars'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'

export type CarWheelsResponse =
  | paths['/admin/cars/{carId}/wheels']['get']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined

type QueryOpts = UseQueryOptions<unknown, unknown, CarWheelsResponse>

export function useCarWheelsQuery(id?: string, opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.carWheels(id),
    async () => {
      const res = await apiAuthFetcher<CarWheelsResponse>(URLS.carWheels(id))
      return res.json
    },
    opts
  )
}
