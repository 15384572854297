import { addSearchParams } from 'driverama-core/utils/searchParams'
import { Maybe } from 'driverama-core/utils/types'

export const getImageIndex = (
  images: { url: Maybe<string> | undefined }[] | undefined,
  url: Maybe<string> | undefined
) => {
  if (!images) {
    return 0
  }
  return images.findIndex(image => image.url === url)
}

export function getObjectFitSize(
  containerWidth: number,
  containerHeight: number,
  width: number,
  height: number
) {
  const doRatio = width / height
  const cRatio = containerWidth / containerHeight
  let targetWidth = 0
  let targetHeight = 0
  const test = doRatio > cRatio

  if (test) {
    targetWidth = containerWidth
    targetHeight = targetWidth / doRatio
  } else {
    targetHeight = containerHeight
    targetWidth = targetHeight * doRatio
  }

  return {
    width: targetWidth,
    height: targetHeight,
    x: (containerWidth - targetWidth) / 2,
    y: (containerHeight - targetHeight) / 2
  }
}

export function removePixelsFromString(input: string): number {
  return Number(input.replace(/px/g, ''))
}

export function resolveImageUrl(url: Maybe<string>) {
  return url
    ? addSearchParams('/_next/image', { url, w: 1920, q: 95 })
    : undefined
}

async function prefetchImage(url: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = url
    img.onload = () => resolve()
    img.onerror = () => reject()
  })
}

export async function prefetchImages(
  images: { url: Maybe<string> | undefined }[],
  index: number
) {
  const imageCountToPrefetch = 3

  for (let i = 0; i <= imageCountToPrefetch; i++) {
    const nextImage = images[index + i]
    const previousImage = images[index - i]

    const nextImageUrl = resolveImageUrl(nextImage?.url)
    if (nextImageUrl) {
      await prefetchImage(nextImageUrl)
    }

    const prevImageUrl = resolveImageUrl(previousImage?.url)
    if (prevImageUrl) {
      await prefetchImage(prevImageUrl)
    }
  }
}
