import { StrikeTextBodyBold } from 'components/diff/StrikeTextBodyBold'
import { TextBodyBold } from 'driverama-core/components/text/Text'
import IconStar from 'images/icons/IconStar.svg'
import { useTranslation } from 'react-i18next'
import {
  SContainer,
  SStar,
  SStars
} from './AuctionsDetailConditionRating.styled'

interface Props {
  rating: number | undefined
  isDiff?: boolean
}

export function AuctionsDetailConditionRating({ rating, isDiff }: Props) {
  const { t } = useTranslation(['auction'])

  if (isDiff && !rating) {
    return null
  }

  const TextComponent = isDiff ? StrikeTextBodyBold : TextBodyBold

  return (
    <SContainer>
      <SStars>
        {[1, 2, 3, 4, 5].map(value => (
          <SStar
            key={value}
            isHighlighted={!!rating && rating >= value}
            isDiff={isDiff}
          >
            <IconStar />
          </SStar>
        ))}
      </SStars>
      {
        <TextComponent>
          {t(
            `auction:detail_condition_${
              rating || 0
            }_rating` as `auction:detail_condition_${1 | 2 | 3 | 4 | 5}_rating`
          )}
        </TextComponent>
      }
    </SContainer>
  )
}
