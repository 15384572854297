import { operations } from 'api/driverama/generated/cars'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'

export type CarAccidentResponse =
  | operations['getAccident']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined

type QueryOpts = UseQueryOptions<unknown, unknown, CarAccidentResponse>

export function useCarAccidentQuery(id?: string, opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.carAccident(id),
    async () => {
      const res = await apiAuthFetcher<CarAccidentResponse>(
        URLS.carAccident(id)
      )
      return res.json
    },
    opts
  )
}
