import {
  AnalyticsEvent,
  ExtractEventParameters,
  GetEventNames
} from 'driverama-core/analytics/types'
import { hasWindow } from 'driverama-core/utils/dom'

type Events =
  | AnalyticsEvent<
      'auction_detail_viewed',
      {
        auction_type: 'upcoming' | 'longterm'
        // not_interested: '0' | '1' //TODO: Add when interest is implemented
        make_model: string
        year: number
        auction_id: string
        partner_id: string | null
        remaining_time?: number
      }
    >
  | AnalyticsEvent<
      'photo_viewed',
      {
        auction_id?: string
      }
    >
  | AnalyticsEvent<
      'detail_viewed',
      {
        name: string
        category: 'mechanical' | 'exterior' | 'interior' | string
        auction_id: string
      }
    >
  | AnalyticsEvent<
      'auction_not_interested',
      {
        auction_id: string
      }
    >
  | AnalyticsEvent<
      'place_bid',
      {
        auction_id: string
        remaining_time: number
      }
    >
  | AnalyticsEvent<
      'bid_placed',
      {
        auction_id: string
        remaining_time: number
        price: number
        currency: string
      }
    >
  | AnalyticsEvent<
      'edit_bid',
      {
        auction_id: string
        remaining_time: number
      }
    >
  | AnalyticsEvent<
      'bid_edited',
      {
        auction_id: string
        remaining_time: number
        price: number
        currency: string
      }
    >
  | AnalyticsEvent<
      'bid_removed',
      {
        auction_id: string
        remaining_time: number
        price: number
        currency: string
      }
    >
  | AnalyticsEvent<
      'auctions_history_viewed',
      {
        filter: 'all' | 'lost' | 'won' | 'buying' | 'completed_paid'
      }
    >
  | AnalyticsEvent<
      'not_interested_viewed',
      {
        auction_type: 'upcoming' | 'longterm'
      }
    >
  | AnalyticsEvent<'notifications_open'>

type EventNames = GetEventNames<Events>

export function logEvent<EventName extends EventNames>(
  eventName: EventName,
  parameters?: ExtractEventParameters<EventName, Events>
) {
  if (!hasWindow() || !window.gtag) {
    return
  }

  window.gtag('event', eventName, parameters ? parameters : undefined)
}
