import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { TextBody, TextSubhead } from 'driverama-core/components/text/Text'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { AuctionsDetailTechnicalDivider } from 'sections/auctions/detail/technical/divider/AuctionsDetailTechnicalDivider'
import { AuctionsDetailTechnicalItems } from 'sections/auctions/detail/technical/items/AuctionsDetailTechnicalItems'
import { logEvent } from 'utils/analytics'
import { useSections } from './AuctionsDetailTechnical.utils'

interface Props {
  carId: string
  auctionId: string | undefined
}

export function AuctionsDetailTechnical({ carId, auctionId }: Props) {
  const { t } = useTranslation(['auction', 'core'])

  const { data, isLoading, isError } = useSections(carId)

  const onDetailExpand = (name: string, sectionId: string | undefined) => {
    if (auctionId && sectionId) {
      logEvent('detail_viewed', {
        auction_id: auctionId,
        category: sectionId.toLowerCase(),
        name
      })
    }
  }

  return isLoading ? (
    <SpinnerCentered />
  ) : isError || !data ? (
    <TextBody>{t('core:error_api')}</TextBody>
  ) : (
    <section>
      <TextSubhead>{t('auction:detail_technical')}</TextSubhead>

      {data.map(section => (
        <Fragment key={section.sectionId}>
          <AuctionsDetailTechnicalDivider>
            {section.name}
          </AuctionsDetailTechnicalDivider>

          <AuctionsDetailTechnicalItems
            carId={carId}
            auctionId={auctionId}
            sectionId={section.sectionId}
            onDetailExpand={name => onDetailExpand(name, section.lovSectionId)}
          />
        </Fragment>
      ))}
    </section>
  )
}
