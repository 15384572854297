import { defaultAuthCookies } from 'api/auth/cookies'
import { useCookie } from 'react-use'

export function usePartnerId() {
  const cookies = defaultAuthCookies()
  const [partnerId] = useCookie(cookies.profile.key)

  if (driverama.flags.IS_KEYCLOAK_ENABLED) {
    return partnerId
  }

  return partnerId
}
