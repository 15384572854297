import { isNotNil } from 'driverama-core/utils/types'
import { useCallback } from 'react'
import { useMutation } from 'react-query'
import {
  resolvePrintUrl,
  useCarImages
} from '../gallery/AuctionsDetailGallery.utils'

function useCachePhotos(urls: string[]) {
  const cacheFn = useCallback(async () => {
    return Promise.all(
      urls.map(
        url =>
          new Promise<HTMLImageElement>((resolve, reject) => {
            const image = new Image()
            image.src = resolvePrintUrl(url)
            image.onload = () => resolve(image)
            image.onerror = reject
          })
      )
    )
  }, [urls])

  return useMutation(cacheFn)
}

export function useExportButton(carId: string) {
  const carImages = useCarImages(carId)
  const cachePhotos = useCachePhotos(
    carImages.data?.map(photo => photo.url).filter(isNotNil) ?? []
  )

  const queries = [carImages, cachePhotos]
  const isLoading = queries.some(query => query.isLoading)
  const isError = queries.some(query => query.isError)

  const exportPdf = async () => {
    await cachePhotos.mutateAsync()

    window.dispatchEvent(new Event('beforeprint')) // window.print doesn't trigger beforeprint event
    setTimeout(() => {
      // We need to wait 1ms to change title of page
      window.print()
    }, 5000)
  }

  return {
    isLoading,
    isError,
    exportPdf
  }
}
