import { TypedAuctionDiff } from 'api/driverama/auctions/diff'
import {
  BonusMalus,
  useLovBonusesMalusesQuery
} from 'api/driverama/lov/lovBonusesMaluses'
import { isNotNil } from 'driverama-core/utils/types'
import { useMemo } from 'react'

export type LovBonusMalusWithDiff = BonusMalus & {
  changeType?: 'ARRAY_ELEMENT_ADDED' | 'ARRAY_ELEMENT_REMOVED'
}

export function useBonusesMalusesWithDiffs(
  bonuses: BonusMalus[],
  maluses: BonusMalus[],
  diffs: TypedAuctionDiff<'$.bonusesMaluses'>[]
): {
  bonusesWithDiff: LovBonusMalusWithDiff[]
  malusesWithDiff: LovBonusMalusWithDiff[]
} {
  const bonusesMalusesQuery = useLovBonusesMalusesQuery()

  const bonusesMalusesDiffs: LovBonusMalusWithDiff[] = useMemo(() => {
    if (!!diffs && !!bonusesMalusesQuery.data) {
      return diffs
        .map(item => {
          if (item.changeType === 'ARRAY_ELEMENT_ADDED') {
            const newValue = bonusesMalusesQuery.data.content.find(
              bonusMalus => bonusMalus.id === item.newValue
            )

            if (newValue) {
              return {
                ...newValue,
                changeType: item.changeType
              }
            }
          }

          if (item.changeType === 'ARRAY_ELEMENT_REMOVED') {
            const oldValue = bonusesMalusesQuery.data.content?.find(
              bonusMalus => bonusMalus.id === item.oldValue
            )

            if (oldValue) {
              return {
                ...oldValue,
                changeType: item.changeType
              }
            }
          }

          return undefined
        })
        .filter(isNotNil)
    }

    return []
  }, [diffs, bonusesMalusesQuery.data])

  return useMemo(() => {
    const diffBonuses = bonusesMalusesDiffs.filter(
      ({ type }) => type === 'BONUS'
    )

    const unchangedBonuses = bonuses.filter(
      bonus => !diffBonuses.find(changedBonus => changedBonus.id === bonus.id)
    )

    const diffMaluses = bonusesMalusesDiffs.filter(
      ({ type }) => type === 'MALUS'
    )

    const unchangedMaluses = maluses.filter(
      malus => !diffMaluses.find(diffMalus => diffMalus.id === malus.id)
    )

    return {
      bonusesWithDiff: [...diffBonuses, ...unchangedBonuses],
      malusesWithDiff: [...diffMaluses, ...unchangedMaluses]
    }
  }, [bonuses, maluses, bonusesMalusesDiffs])
}
