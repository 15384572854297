import { AuctionDiff } from 'api/driverama/auctions/diff'
import { unique } from 'driverama-core/utils/array'
import { isNotNil } from 'driverama-core/utils/types'

const CAR_TECHNICAL_DETAIL_ITEM_ID_REGEX = /\[(.*?)\]/

export type TechnicalDetailsDiff = {
  id: string
  carId: string
  subCategoryId: string
  itemId: string
  name?: string
  customItemDescription?: string
  note?: string
  expectedRepairCostEur?: number
  changeType?: Extract<
    AuctionDiff['changeType'],
    'VALUE_ADDED' | 'VALUE_REMOVED' | 'VALUE_CHANGED'
  >
  repairState: boolean
}

/**
 * Function that takes car auction diffs and return formatted "Cost diffs"
 * @param diffs AuctionDiff[]
 *
 * @return TechnicalDetailsDiff[]
 */
export function processTechnicalDetailsDiffs(
  diffs: AuctionDiff[]
): TechnicalDetailsDiff[] {
  const diffIds = unique(
    diffs
      .map(
        diff => diff.propertyName.match(CAR_TECHNICAL_DETAIL_ITEM_ID_REGEX)?.[1]
      )
      .filter(isNotNil)
  )

  return diffIds.map(id =>
    diffs
      .filter(diff => diff.propertyName.includes(id))
      .reduce((acc, curr) => {
        if (
          curr.changeType === 'VALUE_ADDED' ||
          curr.changeType === 'VALUE_REMOVED' ||
          curr.changeType === 'VALUE_CHANGED'
        ) {
          const propertyNameParts = curr.propertyName.split('.')
          const attribute = propertyNameParts[propertyNameParts.length - 1]

          const diffValue =
            curr.changeType === 'VALUE_ADDED' ? curr.newValue : curr.oldValue
          const value =
            attribute === 'expectedRepairCostEur'
              ? Number(diffValue)
              : diffValue

          return {
            ...acc,
            id,
            changeType: curr.changeType,
            [attribute]: value
          }
        }

        return acc
      }, {} as TechnicalDetailsDiff)
  )
}
