import styled from '@emotion/styled'
import { Chip } from 'driverama-core/components/chip/Chip'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, shadow } from 'driverama-core/styles/variables'

export const SGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${size(10)};

  @media ${media.lte('tablet')} {
    grid-row-gap: ${size(10)};
  }
`

export const SItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${size(4)} 0;

  border-bottom: 2px solid ${color('night-l-650')};
`

export const SShowMoreButton = styled(Chip)`
  background-color: ${color('night-l-100')};

  margin: 0 auto;

  box-shadow: ${shadow(3)};
  border: 2px solid ${color('night-l-100')};
`
