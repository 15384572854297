import styled from '@emotion/styled'
import { Tooltip } from 'driverama-core/components/tooltip/Tooltip'
import { size } from 'driverama-core/styles/spacing'

export const SToolTip = styled(Tooltip)`
  height: ${size(6)};
  display: flex;
  align-items: center;
  padding-right: ${size(2)};
  margin-right: -${size(2)};
`
