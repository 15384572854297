import { useCarTechnicalCategories } from 'api/driverama/cars/carTechnicalCategories'
import { useLovTechnicalCategoriesQuery } from 'api/driverama/lov/lovTechnicalCategories'
import { useContext, useMemo } from 'react'
import { AuctionDiffContext } from '../../diff/Diff.utils'

export function useCategories(carId: string, sectionId: string) {
  const { diffs } = useContext(AuctionDiffContext)

  const categories = useCarTechnicalCategories(
    { carId, sectionId },
    { refetchOnWindowFocus: false }
  )

  const lovCategories = useLovTechnicalCategoriesQuery(
    { filter: { ids: [], sectionIds: [sectionId] } },
    undefined,
    { refetchOnWindowFocus: false }
  )

  const data = useMemo(() => {
    if (!!categories.data && !!lovCategories) {
      return categories.data?.categories
        .sort((a, b) => b.problematicItemsCount - a.problematicItemsCount)
        .map(category => ({
          name: lovCategories.data?.content.find(
            item => item.id === category.categoryId
          )?.name,
          ...category,
          problematicItemsCountDiff: diffs.find(diff =>
            diff.propertyName.includes(
              `categories.[${category.id}].problematicItemsCount`
            )
          )
        }))
    }
    return undefined
  }, [categories.data, lovCategories, diffs])

  return {
    data,
    isLoading: categories.isLoading || lovCategories.isLoading,
    isError: categories.isError || lovCategories.isError
  }
}
