import styled from '@emotion/styled'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${size(248)};
  max-width: 100%;
  margin: 0 auto;

  @media ${media.lte('laptop')} {
    width: 100%;
    padding: ${size(4)};
  }
`

export const SSpinner = styled(SpinnerCentered)`
  height: 100%;
`
