import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ButtonTransparent } from 'driverama-core/components/button/Button'
import { TextBody } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import { size, spaceX } from 'driverama-core/styles/spacing'
import { color, radius, shadow, time } from 'driverama-core/styles/variables'
import IconCheckCircleFilled from 'images/icons/IconCheckCircleFilled.svg'
import IconImage from 'images/icons/IconImage.svg'

export const SContainer = styled.div<{ isOpen: boolean }>`
  display: block;
  break-inside: avoid;
  padding: ${size(6)};

  background-color: ${color('white')};
  border-radius: ${radius('corner')};
  transition: all ${time('control')} ease-in-out;

  ${({ isOpen }) => isOpen && `box-shadow: ${shadow(3)}`};

  @media print {
    box-shadow: none;
  }

  @media ${media.lte('tablet')} {
    padding: ${size(3)};
  }
`

export const SHeader = styled(ButtonTransparent)`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SArrow = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;

  color: ${color('night-text')};
  transition: all ${time('control')} ease-in-out;

  ${({ isOpen }) => isOpen && 'transform: rotate(180deg)'}
`

export const SHeaderIcons = styled.div`
  display: flex;
  align-items: center;

  ${spaceX(2)};
`

export const SCount = styled.div<{ isDiff?: boolean; hasAdded?: boolean }>`
  width: ${size(5)};
  height: ${size(5)};

  background-color: ${color('alert')};
  border-radius: ${radius('full')};

  ${({ isDiff }) =>
    isDiff &&
    css`
      opacity: 0.4;
      background-color: ${color('night-l-200')};
      span {
        text-decoration: line-through;
      }
    `}

  ${({ hasAdded }) =>
    hasAdded &&
    css`
      background-color: ${color('black')};
    `}
`

export const SNumber = styled(TextBody)`
  color: ${color('white')};
`

export const SCheck = styled(IconCheckCircleFilled)`
  width: ${size(5)};
  height: ${size(5)};

  color: ${color('night-l-100')};
`

export const SIconImage = styled(IconImage)<{ hasAdded: boolean }>`
  width: ${size(6)};
  height: ${size(6)};

  color: ${color('alert')};

  ${({ hasAdded }) => css`
    color: ${hasAdded ? color('black') : color('alert')};
  `}
`
