import { useTranslation } from 'react-i18next'
import { SExportButton } from './ExportButton.styled'
import { useExportButton } from './ExportButton.utils'

type Props = {
  carId: string
}

export function ExportButton(props: Props) {
  const { t } = useTranslation(['core'])
  const { isLoading, exportPdf } = useExportButton(props.carId)

  return (
    <SExportButton variant="outline" onClick={exportPdf}>
      {isLoading ? t('core:preparing_pdf') : t('core:export_pdf')}
    </SExportButton>
  )
}
