import { AuctionDiff } from 'api/driverama/auctions/diff'
import { useCarTechnicalSectionsQuery } from 'api/driverama/cars/carTechnicalSections'
import { useLovTechnicalSectionsQuery } from 'api/driverama/lov/lovTechnicalSections'
import { useMemo } from 'react'

export function useConditionSections(carId: string, diffs: AuctionDiff[]) {
  const opts = { enabled: !!carId, refetchOnWindowFocus: false }

  const sections = useCarTechnicalSectionsQuery({ carId }, opts)
  const lovSections = useLovTechnicalSectionsQuery(undefined, opts)

  const data = useMemo(() => {
    if (!!sections.data && !!lovSections.data) {
      return sections.data.sections
        .sort((a, b) => a.sectionId.localeCompare(b.sectionId))
        .map(section => ({
          sectionId: section.sectionId,
          rating: section.rating,
          name: lovSections.data?.content.find(
            lovSection => lovSection.id === section.sectionId
          )?.name,
          diff: diffs.find(diff => diff.propertyName.includes(section.id))
        }))
    }
    return undefined
  }, [sections.data, lovSections.data, diffs])

  return {
    data,
    isLoading: sections.isLoading || lovSections.isLoading,
    isError: sections.isError || lovSections.isError
  }
}
